import {
  Typography,
  Stack,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  InputAdornment,
} from "@mui/material";
import * as React from "react";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function generate(element: React.ReactElement) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    })
  );
}

export default function AboutProperty() {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Stack className="mx-6" gap={2}>
        <Stack direction={"row"} className="flex items-center justify-between">
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "medium",
              color: "text.secondary",
            }}
          >
            about Campsite name
          </Typography>
          <Button
            variant="outlined"
            size="small"
            sx={{
              lineHeight: "1",
            }}
            onClick={handleClickOpen}
          >
            edit
          </Button>
        </Stack>
        <Typography variant="body1">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in
          venenatis ante. Morbi egestas nisl nec scelerisque consequat.
          Curabitur eu tellus placerat, maximus tortor at, vulputate magna. Duis
          tincidunt diam erat, sed vulputate tortor vestibulum eget. Nunc cursus
          leo nec odio hendrerit, ut mollis urna semper. Lorem ipsum dolor sit
          amet, consectetur adipiscing elit. Nullam in venenatis ante. Morbi
          egestas nisl nec scelerisque consequat. Curabitur eu tellus placerat,
          maximus tortor at, vulputate magna. Duis tincidunt diam erat, sed
          vulputate tortor vestibulum eget. Nunc cursus leo nec odio hendrerit,
          ut mollis urna semper.
        </Typography>
        <Divider
          sx={{
            mt: 2,
          }}
        />
        <List>
          {generate(
            <ListItem dense>
              <ListItemIcon>
                <AutoAwesomeRoundedIcon />
              </ListItemIcon>
              <ListItemText primary="Hot Tub" />
            </ListItem>
          )}
        </List>
        <Divider
          sx={{
            mt: 2,
          }}
        />
      </Stack>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle>
          <Stack
            direction={"row"}
            className="flex items-center justify-between"
          >
            <Typography variant="h6">about Camp Name</Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            <TextField variant="outlined" fullWidth multiline minRows="10" />
            <TextField
              label="USP 01"
              placeholder="property unique selling proposition 01"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AutoAwesomeRoundedIcon
                      sx={{
                        opacity: "0.4",
                      }}
                    />
                  </InputAdornment>
                ),
                style: {
                  borderRadius: "10px",
                },
              }}
              variant="outlined"
              fullWidth
            />
            <TextField
              label="USP 02"
              placeholder="property unique selling proposition 02"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AutoAwesomeRoundedIcon
                      sx={{
                        opacity: "0.4",
                      }}
                    />
                  </InputAdornment>
                ),
                style: {
                  borderRadius: "10px",
                },
              }}
              variant="outlined"
              fullWidth
            />
            <TextField
              label="USP 03"
              placeholder="property unique selling proposition 03"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AutoAwesomeRoundedIcon
                      sx={{
                        opacity: "0.4",
                      }}
                    />
                  </InputAdornment>
                ),
                style: {
                  borderRadius: "10px",
                },
              }}
              variant="outlined"
              fullWidth
            />
            <Button
              variant="contained"
              size="large"
              color="success"
              sx={{
                color: "white",
                borderRadius: "20px",
              }}
              onClick={handleClose}
            >
              update
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}

import { FunctionComponent } from "react";

type MenuOverlayType = {
  onClose?: () => void;
};

const MenuOverlay: FunctionComponent<MenuOverlayType> = ({ onClose }) => {
  return (
    <div className="relative rounded-t-none rounded-b-3xs bg-white shadow-[-4px_4px_4px_rgba(0,_0,_0,_0.25)] box-border overflow-hidden flex flex-row p-5 items-start justify-start max-w-full max-h-full text-left text-sm text-steelblue-100 font-open-sans border-[1px] border-solid border-lightgray">
      <div className="flex flex-col items-start justify-start gap-[20px]">
        <div className="flex flex-row items-center justify-start gap-[10px]">
          <img
            className="relative w-6 h-6 overflow-hidden shrink-0"
            alt=""
            src="/icons.svg"
          />
          <div className="relative leading-[135%] font-semibold">Help</div>
        </div>
        <div className="flex flex-row items-center justify-start gap-[10px]">
          <img
            className="relative w-6 h-6 overflow-hidden shrink-0"
            alt=""
            src="/icons1.svg"
          />
          <div className="relative leading-[135%] font-semibold">Skip</div>
        </div>
      </div>
    </div>
  );
};

export default MenuOverlay;

import { FunctionComponent, useEffect, useState } from "react";
import LanguageFilter from "../components/LanguageFilter";
import RegAppHeader from "../components/core/RegAppHeader";
import { Stack, Typography } from "@mui/material";


const Languages: FunctionComponent = () => {
  const [languages,setLanguages]=useState([]);
  useEffect(()=>{
    (async()=>{
      const master:any = await localStorage.getItem('master');
      setLanguages(await JSON.parse(master).languages);
    })();
  },[])
  return (
    <>
      <RegAppHeader PageTitle={"PROFILE 1 of 2"} openMenuOverlay={function (): void {
      console.log('function not implemented')
      } }  />
      <Stack
        gap={4}
        className="absolute text-steelblue-100 bottom-[4vh] left-[16px] right-[16px]"
      >
        <Stack>
          <Typography variant="h3">Languages</Typography>
          <Typography variant="subtitle2">you speak & understand</Typography>
        </Stack>

        <LanguageFilter languages={languages} />
      </Stack>
    </>
  );
};

export default Languages;

import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledImg = styled(ImageListItem)<{ component?: React.ElementType }>({
  "& .MuiImageListItem-img": {
    width: "60px",
    height: "60px",
    borderRadius: "8px",
  },
});

export default function PropThumbnailList() {
  return (
    <Box
      sx={{
        mt: "-7rem",
      }}
    >
      <ImageList cols={1} gap={1} className="m-0 p-0 w-[76px]">
        {itemData.map((item) => (
          <StyledImg
            key={item.img}
            className="p-[8px] bg-[rgba(0,_0,_0,_0.4)] rounded-[16px]"
          >
            <img
              src={`${item.img}?w=60&h=60&fit=crop&auto=format`}
              srcSet={`${item.img}?w=60&h=60&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
              className="rounded-lg drop-shadow-2xl w-60 h-60"
            />
          </StyledImg>
        ))}
      </ImageList>
    </Box>
  );
}

const itemData = [
  {
    img: "https://source.unsplash.com/random/?camping?order_by=curated&orientation=landscape&license=free&11",
    title: "Property key Image 01",
  },
  {
    img: "https://source.unsplash.com/random/?camping?order_by=curated&orientation=landscape&license=free&12",
    title: "Property key Image 01",
  },
  {
    img: "https://source.unsplash.com/random/?camping?order_by=curated&orientation=landscape&license=free&13",
    title: "Property key Image 01",
  },
  {
    img: "https://source.unsplash.com/random/?camping?order_by=curated&orientation=landscape&license=free&14",
    title: "Property key Image 01",
  },
];

import { FunctionComponent, useCallback } from "react";
import { makeStyles } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ClassNames } from "@emotion/react";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import "./ribbon.css";
import { relative } from "path/posix";
import { Divider, Stack } from "@mui/material";
import DirectionsCarFilledRoundedIcon from "@mui/icons-material/DirectionsCarFilledRounded";
import NumberInput from "./NumberInput";

export default function AddOnCard(props) {
  return (
    <Card
      sx={{
        maxWidth: 240,
        minWidth: 240,
        padding: 1,
        borderRadius: 2,
        position: "relative",
        mx: "8px",
      }}
    >
      <CardContent>
        <Stack gap={2}>
          <Stack
            direction={"row"}
            className="flex justify-between items-center"
          >
            <DirectionsCarFilledRoundedIcon fontSize="large" color="success" />
            {/* <NumberInput /> */}
          </Stack>
          <Typography variant="subtitle1" gutterBottom>
            {props.name}
          </Typography>
          <Typography variant="caption" gutterBottom>
            {props.description}
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{
              color: "#FF5733",
              fontWeight: "bold",
            }}
            className="flex items-center"
          >
            <CurrencyRupeeIcon fontSize="small" /> {props.price}/-
          </Typography>
          <Typography variant="caption" gutterBottom>
           min {props.minperson} | max {props.maxperson} persons
           </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}

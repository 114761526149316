import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import RegAppHeader from "../components/core/RegAppHeader";
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import PortalPopup from "../components/PortalPopup";
import MenuOverlay from "../components/MenuOverlay";
import { useDispatch } from "react-redux";
import  {setLocations, setLocationsName}  from "../Redux/Action";

const containerStyle = {
  width: '100%',
  height: '400px'
};

const PropLocation: FunctionComponent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [location, setLocation] = useState<string>("");
    const [isMenuOverlayOpen, setMenuOverlayOpen] = useState(false);
    const [map, setMap] = useState<any>(null);
    const [geoLocation, setGeoLocation] = useState<{ lat: number; lng: number }>({
      lat: 0,
      lng: 0
    });
    
    const successCallback = (position: GeolocationPosition) => {
      const { latitude, longitude } = position.coords;
      setGeoLocation({ lat: latitude, lng: longitude });
    };
    
    const errorCallback = (error: GeolocationPositionError) => {
      console.error(error.message);
    };
    
    const getLiveLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };
    
    useEffect(() => {
      getLiveLocation();
    }, []);
    
    const { isLoaded } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: "AIzaSyBwu7m5p70Q8i7x4N9fq96YJnt_Ha4oy14"
    });
    
    const onLoad = useCallback(function callback(map) {
      setMap(map);
    }, []);
    
    const onUnmount = useCallback(function callback() {
      setMap(null);
    }, []);

    useEffect(() => {
      dispatch(setLocations(geoLocation));
    }, [geoLocation, dispatch]);
    
    const autoCompleteRef = useRef<any>();
    const inputRef = useRef<HTMLInputElement | null>(null);
    
    const options = {
      componentRestrictions: { country: "ng" },
      fields: ["address_components", "geometry", "icon", "name"],
      types: ["establishment"]
    };

    const openMenuOverlay = useCallback(() => {
      setMenuOverlayOpen(true);
    }, []);
  
    const closeMenuOverlay = useCallback(() => {
      setMenuOverlayOpen(false);
    }, []);

    useEffect(() => {
      const loadGooglePlacesScript = async () => {
        await loadScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyBwu7m5p70Q8i7x4N9fq96YJnt_Ha4oy14&libraries=places');
    
        if (isLoaded && inputRef.current) {
          autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, options);
          autoCompleteRef.current.addListener('place_changed', onPlaceChanged);
        }
      };
    
      loadGooglePlacesScript();
    }, [isLoaded, inputRef.current]);
  
    const onPlaceChanged = () => {
      const place = autoCompleteRef.current.getPlace();
      if (place.geometry) {
        const { lat, lng } = place.geometry.location;
        setGeoLocation({ lat: lat(), lng: lng() });
      }
    };

    useEffect(() => {
      if (isLoaded && inputRef.current) {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, options);
      }
    }, [isLoaded, inputRef.current]);
    
    const onButtonClick = async () => {
      dispatch(setLocationsName(location)); // Dispatch the setLocation action with the location
    await localStorage.setItem('location', location);
      navigate("/property-name"); // Navigate to the next screen
    };
    console.log('location',location)
    return (
      <>
        <RegAppHeader
         openMenuOverlay={openMenuOverlay}
        />
        <Stack gap={4} p={4}>
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={geoLocation}
              zoom={10}
              onLoad={onLoad}
              onUnmount={onUnmount}
            >
              {/* Child components, such as markers, info windows, etc. */}
              <></>
            </GoogleMap>
          ) : <></>}
          
          <Typography variant="h5">Property Location</Typography>
          
          <TextField
             label={"Location"}
             placeholder="Enter location"
             variant="outlined"
             onChange={(e) => setLocation(e.target.value)}
             value={location}
             inputRef={inputRef}
             sx={{
               borderRadius: "10px",
             }}
          />
          
          <input ref={inputRef} style={{ display: 'none' }} />
          
          <Button
            variant="contained"
            size="large"
            color="success"
            sx={{
              color: "white",
              borderRadius: "10px",
            }}
            onClick={onButtonClick}
          >
            NEXT
          </Button>
        </Stack>

        {isMenuOverlayOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeMenuOverlay}
        >
          <MenuOverlay onClose={closeMenuOverlay} />
        </PortalPopup>
 )}
 </>
    );
};

export default PropLocation;
function loadScript(arg0: string) {
  throw new Error("Function not implemented.");
}


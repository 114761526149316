import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Box } from "@mui/material";

type RadioButtonType = {
  text?: string;
  selected?: boolean;
};

const formControlLabelStyle = {
  "& .MuiFormControlLabel-label": {
    fontSize: "0.9rem",
    fontWeight: "600",
  },
};

const RadioButton: React.FunctionComponent<RadioButtonType> = ({ text }) => {
  return (
    <Box className="rounded-8xs font-proza-libre border-[0.5px] border-solid border-lightgray ps-4 pe-2 font-bold">
      <FormControlLabel
        control={
          <Radio
            inputProps={{ "aria-label": "controlled" }}
            icon={<CheckCircleOutlineRoundedIcon />}
            checkedIcon={<CheckCircleRoundedIcon />}
            size="small"
          />
        }
        label={text}
        value={text}
        sx={{ ...formControlLabelStyle }}
      />
    </Box>
  );
};

export default RadioButton;

import { FunctionComponent, useCallback } from "react";
import { Typography, Box, Stack, Button } from "@mui/material";
import StayCard from "../core/StayCard";
import CabinRoundedIcon from "@mui/icons-material/CabinRounded";

export default function PropStays({stay}) {
  return (
    <>
      <Stack
        direction={"row"}
        className="flex items-center justify-between mx-6"
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: "medium",
            color: "text.secondary",
          }}
        >
          stays
        </Typography>
        <Button
          variant="outlined"
          size="small"
          sx={{
            lineHeight: "1",
          }}
        >
          add stay
        </Button>
      </Stack>
      <Box className="overflow-auto w-[100%] flex nowrap">
        <StayCard
          name={stay}
          img="https://source.unsplash.com/random/?camping?order_by=curated&orientation=landscape&license=free&3"
          price="2000"
          pricetype="night"
          available="20"
        />
        {/* <StayCard
          name="Cabin"
          img="https://source.unsplash.com/random/?camping?order_by=curated&orientation=landscape&license=free&5"
          price="1500"
          pricetype="person"
          available="5"
        /> */}
      </Box>
    </>
  );
}

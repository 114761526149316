import axios from "axios";

const token:any = localStorage.getItem('token');
export const axiosInstance = axios.create({
  baseURL: "http://127.0.0.1:3000",
  timeout: 10000,
  headers:{
    'content-type': 'application/json',
    'clientid':'abbas_system',
    'partnertoken':'$2b$10$aoAA/HxIXK2DvCcT81diQuQBAj/mlOUpnQPHyQevo42DqffB8u0zC',
    'domaintoken':'4044da19936f8990c9954f4270b164f4638dfd6b',
    'Origin':'test.thecloudvision.com',
    'Authorization': `Bearer ${token}`,
  }

});

axiosInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

import * as React from "react";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Stack,
  Button,
  Typography,
  Link,
  Breadcrumbs,
  Box,
} from "@mui/material";
import RegAppHeader from "../components/core/RegAppHeader";
import PropImageList from "../components/PropertyView/ImageList";
import PropThumbnailList from "../components/PropertyView/ThumbnailList";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";

import AboutProperty from "../components/PropertyView/AboutProperty";
import BestTime from "../components/PropertyView/BestTime";
import DistanceFrom from "../components/PropertyView/Distance";
import Amenties from "../components/PropertyView/Amenities";

import CheckInOut from "../components/PropertyView/ThingsToKnow";

import PropEvents from "../components/PropertyView/PropEvents";
import PropStays from "../components/PropertyView/PropStays";
import PropExps from "../components/PropertyView/PropExps";
import PropAddOns from "../components/PropertyView/PropAddOns";
import PropHosts from "../components/PropertyView/PropHosts";
import PropNearBy from "../components/PropertyView/PropNearBy";

const ViewProp: FunctionComponent = () => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();
  const [propName, setPropName]=useState<any>();
  const [ammenties, setAmenities]=useState<any>();
  const [seasons, setSeasons]=useState<any>();
  const [checkIn, setCheckIn]=useState<any>();
  const [checkOut, setCheckOut]=useState<any>();
  const [stay, setStay]=useState<any>();
  const onButtonClick = useCallback(() => {
    navigate("/publish");
  }, [navigate]);
  const addEvent = useCallback(() => {
    navigate("/");
  }, [navigate]);
  const manageBestTime = useCallback(() => {
    navigate("/property-best-season");
  }, [navigate]);
  const manageDistance = useCallback(() => {
    navigate("/");
  }, [navigate]);
  const editAbout = useCallback(() => {
    navigate("/");
  }, [navigate]);
  const manageAmenities = useCallback(() => {
    navigate("/");
  }, [navigate]);
  const addStay = useCallback(() => {
    navigate("/");
  }, [navigate]);
  const addExp = useCallback(() => {
    navigate("/");
  }, [navigate]);
  const addAddOn = useCallback(() => {
    navigate("/");
  }, [navigate]);
  const manageHost = useCallback(() => {
    navigate("/");
  }, [navigate]);
  const manageCheckin = useCallback(() => {
    navigate("/");
  }, [navigate]);
  const ManageNearBy = useCallback(() => {
    navigate("/");
  }, [navigate]);

  useEffect(()=>{
    (async()=>{
      setPropName(await localStorage.getItem('propertyName')); 
      setAmenities(await localStorage.getItem('selectedAmmenities')); 
      setSeasons(await localStorage.getItem('selectedSeasons')); 
      setCheckIn(await localStorage.getItem('checkin')); 
      setCheckOut(await localStorage.getItem('checkout')); 
      setStay(await localStorage.getItem('stayName')); 
    })();
  },[])

  return (
    <>
      <RegAppHeader openMenuOverlay={function (): void {
       console.log('function not implemented')
      } } ></RegAppHeader>
      <Stack spacing={0} className="z-auto bg-slate-100">
        <PropImageList />
        <Stack direction={"row"} className="px-4">
          <PropThumbnailList></PropThumbnailList>
          <Stack
            spacing={2}
            justifyContent="space-between"
            alignItems="flex-start"
            className="ms-5"
          >
            <Breadcrumbs maxItems={3} aria-label="breadcrumb" className="pt-4">
              <Link
                underline="hover"
                color="inherit"
                href="/"
                sx={{
                  fontSize: "14px",
                }}
              >
                Camps in
              </Link>
              <Link
                underline="hover"
                color="inherit"
                href="/"
                sx={{
                  fontSize: "14px",
                }}
              >
                State
              </Link>
              <Link
                underline="hover"
                color="inherit"
                href="/"
                sx={{
                  fontSize: "14px",
                }}
              >
                District
              </Link>
              <Typography
                color="text.primary"
                sx={{
                  fontSize: "14px",
                }}
              >
                {" "}
                Locality
              </Typography>
            </Breadcrumbs>
            <Typography variant="h3" gutterBottom>
              {propName}
            </Typography>
            <Stack direction={"row"} spacing={2}>
              <VerifiedRoundedIcon className="opacity-20" />
              <VerifiedRoundedIcon className="opacity-20" />
              <VerifiedRoundedIcon className="opacity-20" />
              <VerifiedRoundedIcon className="opacity-20" />
            </Stack>
          </Stack>
        </Stack>
        <Stack gap={4} className="mt-16">
          <PropEvents />
          <BestTime selected={seasons}/>
          <DistanceFrom />
          <AboutProperty />
          <Amenties selected={ammenties}/>
          <PropStays stay={stay}/>
          <PropExps />
          <PropAddOns />
          <PropHosts />
          <CheckInOut checkin={checkIn} checkout={checkOut}/>
          <PropNearBy />
        </Stack>
        <Button
          variant="contained"
          sx={{
            margin: "16px",
            bgcolor: "#FF5733",
            borderRadius: "8px",
          }}
          onClick={onButtonClick}
        >
          publish property
        </Button>
      </Stack>
    </>
  );
};

export default ViewProp;

import { FunctionComponent, useCallback } from "react";
import { Stack, Link } from "@mui/material";
import Divider from "@mui/material/Divider";

const LegalLink: FunctionComponent = () => {
  return (
    <Stack
      spacing={2}
      direction="row"
      divider={<Divider orientation="vertical" flexItem></Divider>}
      justifyContent="center"
      alignItems="center"
      sx={{
        typography: 'body2',
        '& > :not(style) ~ :not(style)': {
          ml: 2,
        },
      }}
      className="w-[100%]"
    >
       <Link href="#" underline="none" color="text.secondary">
        {'Help'}
      </Link>
      <Link href="#" underline="none"  color="text.secondary">
        {'Privacy'}
      </Link>
      <Link href="#" underline="none"  color="text.secondary">
        {'Terms'}
      </Link>
      <Link href="#" underline="none"  color="text.secondary">
        {'Policy'}
      </Link>
    </Stack>
  );
};

export default LegalLink;

import * as React from "react";
import { FunctionComponent } from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

type TimpickerLabelType = {
  TimepickerLabel: string;
  TimepickerValue: any;
};

const FrtTimePicker: FunctionComponent<TimpickerLabelType> = ({
  TimepickerLabel,TimepickerValue
}) => {
  const [pickerValue, setPickerValue]=React.useState<any>(dayjs("2022-04-17T10:00"));
  const sendTimePickerValue = (value) => {
    setPickerValue(value);
    TimepickerValue(value.$H+':'+value.$M+(value.$H>12 ? ' PM': ' AM'))
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        // defaultValue={dayjs("2022-04-17T10:00")}
        label={TimepickerLabel}
        value={pickerValue}
        onChange={(val:any) => sendTimePickerValue(val)}
        views={["hours", "minutes"]}
        sx={{
          borderRadius: "10px",
        }}
      />
    </LocalizationProvider>
  );
};

export default FrtTimePicker;

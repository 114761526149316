import React from 'react';
import { MuiOtpInput } from 'mui-one-time-password-input';

const OtpInput = () => {
    const [otp, setOtp] = React.useState('')
  
    const handleChange = (NewValue: any) => {
      setOtp(NewValue)
    }
  
    return (
      <MuiOtpInput value={otp} onChange={handleChange} TextFieldsProps={{
        style: {
          borderRadius: "10px",
        },
      }}/>
    )
  }

  export default OtpInput;
import { FunctionComponent } from "react";
import {
  Button,
  Stack,
  Typography,
  Avatar,
  Badge,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { mainModule } from "process";
import { useNavigate } from "react-router-dom";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#FF5733",
    color: "#FF5733",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: "-1px",
      left: "-1px0",
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(1)",
      opacity: 0.5,
    },
    "100%": {
      transform: "scale(2)",
      opacity: 0,
    },
  },
}));

type RegAppHeaderType = {
  PageTitle?: String;
  openMenuOverlay: () => void;
};

const RegAppHeader: FunctionComponent<RegAppHeaderType> = ({ PageTitle, openMenuOverlay }) => {
  const navigate = useNavigate();
  const handleBack = () => {
    
    navigate(-1);
  }
  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="space-between"
      alignItems="center"
      className="px-4 pe-2 pt-1 pb-1 shadow-[0px_1px_1px_rgba(0,_0,_0,_0.1)] sticky top-0 bg-[rgba(255,_255,_255,_0.9)] z-50"
    >
      <Button startIcon={<ArrowBackIosNewRoundedIcon />} size="small" onClick={handleBack}>
        Back
      </Button>
      <Stack direction={"row"}>
        <Typography
          variant="overline"
          sx={{
            opacity: [0.3],
            padding: "0 8px 0 0 ",
          }}
        >
          {PageTitle}
        </Typography>
        {/* /*Please Load the below componet in condition with logged in user */}
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          variant="dot"
        >
          <Avatar
            src=""
            alt="user name"
            sx={{ width: 32, height: 32, boxShadow: 5 }}
          />
        </StyledBadge>
        <IconButton
        // onClick={}
        onClick={openMenuOverlay}
        >
          <MoreVertIcon></MoreVertIcon>
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default RegAppHeader;

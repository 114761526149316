import * as React from "react";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import { FunctionComponent, useCallback } from "react";
import WifiRoundedIcon from "@mui/icons-material/WifiRounded";
import RestaurantRoundedIcon from "@mui/icons-material/RestaurantRounded";
import LocalParkingRoundedIcon from "@mui/icons-material/LocalParkingRounded";
import LocalFireDepartmentRoundedIcon from "@mui/icons-material/LocalFireDepartmentRounded";
import WcRoundedIcon from "@mui/icons-material/WcRounded";
import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  Typography,
  Box,
  Stack,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  Checkbox,
  Switch,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Label } from "@mui/icons-material";

const Amenities = [
  { icon: <WifiRoundedIcon />, label: "Wifi", color: "#FF5733" },
  { icon: <RestaurantRoundedIcon />, label: "Restaurant", color: "#0994CF" },
  { icon: <LocalParkingRoundedIcon />, label: "Parking", color: "#77A306" },
  {
    icon: <LocalFireDepartmentRoundedIcon />,
    label: "Fire Ring",
    color: "#B74C49",
  },
  { icon: <WcRoundedIcon />, label: "Common Toilet1", color: "#323232" },
  { icon: <WcRoundedIcon />, label: "Common-Toilet2", color: "#323232" },
  { icon: <WcRoundedIcon />, label: "Common=Toilet3", color: "#323232" },
];

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function Amenties({selected}:any) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [checked, setChecked] = React.useState([1]);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <>
      <Stack gap={2}>
        <Stack
          direction={"row"}
          className="flex items-center justify-between mx-6"
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "medium",
              color: "text.secondary",
            }}
          >
            amenities
          </Typography>
          <Button
            variant="outlined"
            size="small"
            sx={{
              lineHeight: "1",
            }}
            onClick={handleClickOpen}
          >
            manage
          </Button>
        </Stack>
        <Stack
          direction={"row"}
          gap={1}
          className="overflow-auto flex items-start nowrap w-[100vw]"
        >
          <div className="w-[16px] invisible">.</div>
          { selected && JSON.parse(selected).map((ammenity) => (
            <Box
              className="w-[56px] flex flex-col justify-center gap-2 m-2"
              key={ammenity._id}
            >
              <Card
                className="bg-white p-4"
                sx={{
                  borderRadius: "16px",
                  // color: Amenity.color,
                }}
              >
                <WcRoundedIcon />
              </Card>
              <Typography variant="caption" className="text-center">
                {ammenity.name}
              </Typography>
            </Box>
          ))}
          <div className="w-[16px] invisible">.</div>
        </Stack>
      </Stack>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle>
          <Stack
            direction={"row"}
            className="flex items-center justify-between"
          >
            <Typography variant="h6">manage amenities</Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            <List
              dense
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            >
              {Amenities.map((Amenity) => {
                const labelId = `checkbox-list-secondary-label-${Amenity.label}`;
                return (
                  <ListItem
                    key={labelId}
                    secondaryAction={
                      <Switch
                        edge="end"
                        onChange={handleToggle(0)}
                        checked={checked.indexOf(1) !== -1}
                        inputProps={{
                          "aria-labelledby": "switch-list-label-wifi",
                        }}
                      />
                    }
                    disablePadding
                  >
                    <ListItemButton
                      role={undefined}
                      onClick={handleToggle(0)}
                      dense
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={checked.indexOf(1) !== -1}
                          tabIndex={-1}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={Amenity.label}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
            <Button
              variant="contained"
              size="large"
              color="success"
              sx={{
                color: "white",
                borderRadius: "10px",
              }}
              onClick={handleClose}
            >
              update
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}

import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import { useEffect } from "react";
import { ThemeProvider } from "@mui/material";
import { FrtTheme } from "./components/core/ThemeLight";
import Splash from "./pages/Splash";

import { Provider } from 'react-redux';

import StartHosting from "./pages/StartHosting";

import NewSignUp from "./pages/NewSignUp";
import VerifyOTP from "./pages/VerifyOTP";
import Start from "./pages/Start";
import PropLocation from "./pages/PropLocation";
import PropName from "./pages/PropName";

import PropAmenities from "./pages/PropAmenities";
import PropCheckInOut from "./pages/PropcheckInOut";
import StayTypeList from "./pages/StayTypes";
import StayDetails from "./pages/StayDetails";
import PropRules from "./pages/PropRules";
import PropPolciy from "./pages/PropPolicy";
import ViewProp from "./pages/ViewProp";
import Publish from "./pages/Publish";
import Boarding from "./pages/Boarding";
import Languages from "./pages/languages";
import Profile from "./pages/profile";
import Seasons from "./pages/seasons";

import Terrains from "./pages/terrains";
import { store } from "./Redux/Store";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/introcards":
        title = "";
        metaDescription = "";
        break;
      case "/start-hosting":
        title = "";
        metaDescription = "";
        break;
      case "/profile-info-2":
        title = "";
        metaDescription = "";
        break;
      case "/profile-info-01":
        title = "";
        metaDescription = "";
        break;
      case "/new-sign-up":
        title = "";
        metaDescription = "";
        break;
      case "/verify-otp":
        title = "";
        metaDescription = "";
        break;
      case "/start":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Provider store={store}>
    <ThemeProvider theme={FrtTheme}>
      <Routes>
        <Route path="/" element={<Splash />} />
        <Route path="/boarding" element={<Boarding />} />
        <Route path="/login" element={<Start />} />
        <Route path="/register" element={<NewSignUp />} />
        <Route path="/verify-otp" element={<VerifyOTP />} />
        <Route path="/languages" element={<Languages />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/start-hosting" element={<StartHosting />} />

        <Route path="/property-name" element={<PropName />} />
        <Route path="/property-location" element={<PropLocation />} />


        <Route path="/best-season" element={<Seasons />} />

        <Route path="/terrains" element={<Terrains />} />
        <Route path="/amenities" element={<PropAmenities />} />
        <Route path="/property-checkinout" element={<PropCheckInOut />} />
        <Route path="/stay-type" element={<StayTypeList />} />
        <Route path="/stay-details" element={<StayDetails />} />
        <Route path="/property-rules" element={<PropRules />} />
        <Route path="/property-policy" element={<PropPolciy />} />
        <Route path="/view-property" element={< ViewProp/>} />
        <Route path="/publish" element={<Publish />} />
      </Routes>
    </ThemeProvider>
    </Provider>
  );
}
export default App;

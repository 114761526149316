import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import RegAppHeader from "../components/core/RegAppHeader";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import CabinRoundedIcon from "@mui/icons-material/CabinRounded";
import { Box, Stack } from "@mui/material";

export default function StayTypeList() {

  const navigate = useNavigate();
  const onButtonClick = (type:any) => {
    localStorage.setItem('type',type);
    navigate("/stay-details");
  }

  return (
    <>
      <RegAppHeader openMenuOverlay={function (): void {
       console.log('function not implemented')
      } } ></RegAppHeader>
      <Stack gap={4} p={4}>
        <Box>
          <Typography variant="h5">What kind of stays you have?</Typography>
          <Typography variant="caption">
            Begin with one kind. More kinds can always be added later.
          </Typography>
        </Box>

        <MenuList>
          <MenuItem
            sx={{
              border: 1,
              borderRadius: 4,
              mb: "8px",
            }}
            onClick={()=>onButtonClick('Tent House')}
          >
            <ListItemIcon>
              <CabinRoundedIcon style={{ color: "#575A89" }} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <Typography
                  variant="h6"
                  style={{ color: "#575A89", lineHeight: "14px" }}
                >
                  Tent House
                </Typography>
              }
              secondary={
                <Typography variant="caption" style={{ color: "#6f6f6f" }}>
                  Guest can bring their own tent
                </Typography>
              }
              style={{ margin: "16px 0 8px 8px" }}
            />

            <Typography variant="body2">
              <NavigateNextRoundedIcon></NavigateNextRoundedIcon>
            </Typography>
          </MenuItem>
          <MenuItem
            sx={{
              border: 1,
              borderRadius: 4,
            }}
            onClick={()=>onButtonClick('Camp ground')}
          >
            <ListItemIcon>
              <CabinRoundedIcon style={{ color: "#575A89" }} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <Typography
                  variant="h6"
                  style={{ color: "#575A89", lineHeight: "14px" }}
                >
                  Camp ground
                </Typography>
              }
              secondary={
                <Typography variant="caption" style={{ color: "#6f6f6f" }}>
                  Guest can bring their own tent
                </Typography>
              }
              style={{ margin: "16px 0 8px 8px" }}
            />

            <Typography variant="body2">
              <NavigateNextRoundedIcon></NavigateNextRoundedIcon>
            </Typography>
          </MenuItem>
        </MenuList>
      </Stack>
    </>
  );
}

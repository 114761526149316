import { Typography, Stack, Divider, Button } from "@mui/material";
import * as React from "react";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import PolicyCard from "../core/PolicyCard";


export default function CheckInOut({checkin, checkout}) {
  return (
    <Stack className="mx-6" gap={4}>
      <Stack direction={"row"} className="flex items-center justify-between">
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: "medium",
            color: "text.secondary",
          }}
        >
          things to know
        </Typography>
        <Button
          variant="outlined"
          size="small"
          sx={{
            lineHeight: "1",
          }}
        >
          manage
        </Button>
      </Stack>
      <Stack direction={"row"} gap={2}>
        <LastPageRoundedIcon fontSize="large" color="success" />
        <Stack gap={1}>
          <Typography
            variant="body1"
            sx={{
              color: "rgba(43,55,65,0.5)",
            }}
          >
            Check In
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "800",
            }}
          >
            After {checkin}.
          </Typography>
          <Typography variant="caption" className="opacity-60">
            Early check in might be available. Please message host for more
            info.
          </Typography>
        </Stack>
      </Stack>
      <Divider
        sx={{
          my: 2,
        }}
      />
      <Stack direction={"row"} gap={2}>
        <FirstPageRoundedIcon fontSize="large" color="success" />
        <Stack gap={1}>
          <Typography
            variant="body1"
            sx={{
              color: "rgba(43,55,65,0.5)",
            }}
          >
            Check Out
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "800",
            }}
          >
            Before {checkout}.
          </Typography>
          <Typography variant="caption" className="opacity-60">
            Late check out might be available. Please message host for more
            info.
          </Typography>
        </Stack>
      </Stack>
      <PolicyCard />
    </Stack>
  );
}

import React, { FunctionComponent, useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import RegAppHeader from "../components/core/RegAppHeader";
import { Stack, Button, Box, Typography } from "@mui/material";
import AddAPhotoRoundedIcon from "@mui/icons-material/AddAPhotoRounded";
import CollectionsBookmarkRoundedIcon from "@mui/icons-material/CollectionsBookmarkRounded";
import { useDispatch } from 'react-redux';
import { styled } from "styled-components";
import { uploadImage } from "../Redux/Action";
import PortalPopup from "../components/PortalPopup";
import MenuOverlay from "../components/MenuOverlay";

const Profile: FunctionComponent = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cameraInputRef = useRef<HTMLInputElement>(null); 
  const [isMenuOverlayOpen, setMenuOverlayOpen] = useState(false);



  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result as string);
      };
      reader.readAsDataURL(files[0]);
      setSelectedFile(files[0]);
    }
    
  };

  const handleCameraUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result as string);
      };
      reader.readAsDataURL(files[0]);
      setSelectedFile(files[0]);
      dispatch(uploadImage(files[0]));
      navigate("/start-hosting");
    }
  };

  const openCamera = () => {
    if (cameraInputRef.current) {
      cameraInputRef.current.click(); // Trigger click on the hidden camera input
    }
  };

  // const onUploadClick = () => {
  //   if (selectedFile) {
  //     console.log("Selected File:", selectedFile);
  //     dispatch(uploadImage(selectedFile));
  //     // You can perform further actions with the selected file here
  //   }
  // };

  const onUploadClick = useCallback(() => {
    console.log('Before dispatching uploadImage action');
    if (selectedFile) {
     const image = selectedFile
      console.log('Selected File:', selectedFile);
      dispatch(uploadImage(image));
      navigate("/start-hosting");
      console.log('After dispatching uploadImage action and before navigation');
    }
  }, [navigate, selectedFile]);

  const openMenuOverlay = useCallback(() => {
    setMenuOverlayOpen(true);
  }, []);

  const closeMenuOverlay = useCallback(() => {
    setMenuOverlayOpen(false);
  }, []);
  

  return (
    <>
      <RegAppHeader   openMenuOverlay={openMenuOverlay} PageTitle={"PROFILE 2 OF 2"} />
      <Box className="absolute bottom-[8vh] text-steelblue-100 left-[32px] right-[32px]">
        <Typography variant="h2">
          add your <br /> PHOTO
        </Typography>
        {previewImage && <img src={previewImage} alt="Selected" className="w-[50vw] h-[30vh] mb-10 mt-10" />}
        <Typography variant="subtitle2">show them you are real</Typography>
        {selectedFile && (
          <Button
            variant="contained"
            size="large"
            color="success"
            sx={{
              color: "white",
              borderRadius: "10px",
            }}
            onClick={onUploadClick}
          >
            Upload
          </Button>
        )}
        <Stack gap={2} direction={"row"} justifyContent="center" alignItems="center">
          <Button
           onClick={openCamera} 
            variant="contained"
            size="large"
            color="success"
            sx={{
              color: "white",
              borderRadius: "10px",
            }}
            endIcon={<AddAPhotoRoundedIcon />}
          >
            Camera
            <input
              id="camera-upload"
              type="file"
              accept="image/*"
              capture={true}
              style={{ display: "none" }}
              onChange={handleCameraUpload}
            />
          </Button>
          <Button
            variant="outlined"
            component="label"
            size="large"
            endIcon={<CollectionsBookmarkRoundedIcon />}
            sx={{
              borderRadius: "10px",
            }}
          >
            Gallery
            <input type="file" style={{ display: "none" }} onChange={handleFileChange} />
          </Button>
        </Stack>
      </Box>
      {isMenuOverlayOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeMenuOverlay}
        >
          <MenuOverlay onClose={closeMenuOverlay} />
        </PortalPopup>
      )}
    </>
  );
};

export default Profile;



// const onButtonClick = useCallback(() => {
//   navigate("/start-hosting");
  
// }, [navigate]);


import { Card, CardHeader } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {  IconButton } from "@mui/material";
import GppGoodRoundedIcon from "@mui/icons-material/GppGoodRounded";
import ArrowCircleRightTwoToneIcon from "@mui/icons-material/ArrowCircleRightTwoTone";
import EventBusyRoundedIcon from '@mui/icons-material/EventBusyRounded';

export default function PolicyCard() {
  return (
    <>
      <Card
        className="m-5"
        sx={{
          borderRadius: "16px",
        }}
      >
        <CardHeader
          avatar={<GppGoodRoundedIcon fontSize="medium" color="info" />}
          title="camp rules"
          titleTypographyProps={{ fontSize: "20px", fontWeight: "700", color: 'rgb(64, 196, 255)'}}
        ></CardHeader>
        <CardContent className="flex ">
          <Typography variant="caption">
            For a 50% refund of the nightly rate, the guest must cancel 30 full
            days before the listing’s local check-in time (shown in the
            confirmation email).
          </Typography>
          <CardActions>
            <IconButton aria-label="view">
              <ArrowCircleRightTwoToneIcon fontSize="large" sx={{color:'rgb(64, 196, 255)'}}/>
            </IconButton>
          </CardActions>
        </CardContent>
      </Card>
      <Card
        className="mx-5"
        sx={{
          borderRadius: "16px",
        }}
      >
        <CardHeader
          avatar={<EventBusyRoundedIcon fontSize="medium" color="warning" />}
          title="cancellation policy"
          titleTypographyProps={{ fontSize: "20px", fontWeight: "700", color: '#ff3d00'}}
        ></CardHeader>
        <CardContent className="flex ">
          <Typography variant="caption">
            For a 50% refund of the nightly rate, the guest must cancel 30 full
            days before the listing’s local check-in time (shown in the
            confirmation email).
          </Typography>
          <CardActions>
            <IconButton aria-label="view">
              <ArrowCircleRightTwoToneIcon fontSize="large" sx={{color:'#ff3d00'}}/>
            </IconButton>
          </CardActions>
        </CardContent>
      </Card>
    </>
  );
}

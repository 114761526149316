import { FunctionComponent, useCallback } from "react";
import { Typography, Box, Stack, Button } from "@mui/material";
import HostCard from "../core/HostCard";
import CabinRoundedIcon from "@mui/icons-material/CabinRounded";

export default function PropHosts() {
  return (
    <>
      <Stack
        direction={"row"}
        className="flex items-center justify-between mx-6"
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: "medium",
            color: "text.secondary",
          }}
        >
          hosted by
        </Typography>
        <Button
          variant="outlined"
          size="small"
          sx={{
            lineHeight: "1",
          }}
        >
          manage
        </Button>
      </Stack>
      <Box>
        <HostCard
          hostname="Surajit Das"
          startdate="2009"
          replyrate="moderate"
          languages="English, Hindi, Bengali"
          bio="Lorem ipsum dollar smet"
        />
      </Box>
    </>
  );
}

import { FunctionComponent, useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Stack,
  TextField,
  Typography,
  Button,
  IconButton,
  InputAdornment,
  SvgIcon,
} from "@mui/material";
import { Smartphone } from "@mui/icons-material";

const StartHostingForm: FunctionComponent = () => {
  const navigate = useNavigate();
  const [mobile,setMobile] = useState<any>();
  useEffect(()=>{
    (async()=>{
    setMobile(await localStorage.getItem('mobile'))
  })()
  },[])
  const onButtonClick = () => {
    console.log(mobile);
    localStorage.setItem('mobile',mobile);
    navigate("/register");
  };

  return (
    <>
      <Stack spacing={6}>
        <Stack
          direction={"row"}
          justifyContent="flex-start"
          alignItems="flex-end"
          spacing={2}
        >
          <IconButton
            aria-label="Google"
            size="large"
            //variant="contained"
            sx={{ borderRadius: 4, padding: 2, boxShadow: 2 }}
          >
            <SvgIcon>
              <path
                d="M23.52 12.7729C23.52 11.922 23.4436 11.1038 23.3018 10.3184H12V14.9602H18.4582C18.18 16.4602 17.3345 17.7311 16.0636 18.582V21.5929H19.9418C22.2109 19.5038 23.52 16.4274 23.52 12.7729Z"
                fill="#4285F4"
              />
              <path
                d="M12 24.5003C15.24 24.5003 17.9564 23.4257 19.9418 21.593L16.0636 18.5821C14.9891 19.3021 13.6145 19.7275 12 19.7275C8.87455 19.7275 6.22909 17.6166 5.28546 14.7803H1.27637V17.8894C3.25091 21.8112 7.30909 24.5003 12 24.5003Z"
                fill="#34A853"
              />
              <path
                d="M5.28545 14.7799C5.04545 14.0599 4.90909 13.2908 4.90909 12.4999C4.90909 11.709 5.04545 10.9399 5.28545 10.2199V7.11084H1.27636C0.463636 8.73084 0 10.5636 0 12.4999C0 14.4363 0.463636 16.269 1.27636 17.889L5.28545 14.7799Z"
                fill="#FBBC05"
              />
              <path
                d="M12 5.27273C13.7618 5.27273 15.3436 5.87818 16.5873 7.06727L20.0291 3.62545C17.9509 1.68909 15.2345 0.5 12 0.5C7.30909 0.5 3.25091 3.18909 1.27637 7.11091L5.28546 10.22C6.22909 7.38364 8.87455 5.27273 12 5.27273Z"
                fill="#EA4335"
              />
            </SvgIcon>
          </IconButton>
          <IconButton
            aria-label="Facebook"
            size="large"
            //variant="contained"
            sx={{ borderRadius: 4, padding: 2, boxShadow: 2 }}
          >
            <SvgIcon>
              <path
                d="M12 0.5C8.8174 0.5 5.76515 1.76428 3.51472 4.01472C1.26428 6.26515 0 9.3174 0 12.5C0 15.6826 1.26428 18.7348 3.51472 20.9853C5.76515 23.2357 8.8174 24.5 12 24.5C15.1826 24.5 18.2348 23.2357 20.4853 20.9853C22.7357 18.7348 24 15.6826 24 12.5C24 9.3174 22.7357 6.26515 20.4853 4.01472C18.2348 1.76428 15.1826 0.5 12 0.5Z"
                fill="#039BE5"
              />
              <path
                d="M13.6244 15.6806H16.7299L17.2175 12.5259H13.6238V10.8017C13.6238 9.49116 14.052 8.32905 15.2779 8.32905H17.2478V5.576C16.9017 5.52926 16.1697 5.42695 14.7865 5.42695C11.8983 5.42695 10.2051 6.95221 10.2051 10.4272V12.5259H7.236V15.6806H10.2051V24.3516C10.7931 24.44 11.3886 24.5 12 24.5C12.5526 24.5 13.092 24.4495 13.6244 24.3775V15.6806Z"
                fill="white"
              />
            </SvgIcon>
          </IconButton>
          <Typography
            variant="caption"
            sx={{
              color: "text.secondary",
            }}
          >
            or
          </Typography>
        </Stack>
        <Stack spacing={2}>
          <TextField
            label="phone number"
            placeholder="Mobile number"
            onChange={event => setMobile(event.target.value)}
            value={mobile}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Smartphone
                    sx={{
                      opacity: "0.4",
                    }}
                  ></Smartphone>
                </InputAdornment>
              ),
              style: {
                borderRadius: "10px",
              },
            }}
            variant="outlined"
          />
          <Button
            variant="contained"
            size="large"
            color="success"
            sx={{
              color: "white",
              borderRadius: "10px",
            }}
            onClick={onButtonClick}
          >
            next
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default StartHostingForm;

import { FunctionComponent } from "react";
import StartHostingForm from "../components/StartHostingForm";
import { Box, Typography } from "@mui/material";

const StartHostingPhoneOrSocial: FunctionComponent = () => {
  
  return (
    <div className="absolute bottom-[16vh] p-4 right-4 left-4">
      <Typography variant="h2" sx={{
        color:"text.primary"
      }} className="pb-8">
        Start
        <br />
        Hosting
      </Typography>

      <StartHostingForm />
    </div>
  );
};

export default StartHostingPhoneOrSocial;

import { FunctionComponent, useCallback, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import {
  Stack,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import RegAppHeader from "../components/core/RegAppHeader";
import FrtTimePicker from "../components/core/TimePicker";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { useDispatch } from 'react-redux';
import  {setEarlyCheckIn, setEarlyCheckOut, setCheckinTimee, setCheckoutTimee}  from '../Redux/Action';

const PropCheckInOut: FunctionComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checkin, setCheckin]=useState<any>('10:00 AM');
  const [checkout, setCheckout]=useState<any>('10:00 AM');
  const [earlyCheckin, setEarlyCheckin]=useState<any>(false);
  const [earlyCheckout, setEarlyCheckout]=useState<any>(false);

  const onButtonClick = async () => {
    console.log('earlyCheckin===',earlyCheckin)
    console.log('earlyCheckout===',earlyCheckout)
    await localStorage.setItem('checkin',checkin);
    await localStorage.setItem('checkout',checkout);
    await localStorage.setItem('earlycheckin',earlyCheckin);
    await localStorage.setItem('earlycheckout',earlyCheckout);
    navigate("/stay-type");
  }

  const handleCheckinChange = (time: any) => {
    console.log('Selected Check-in Time:', time);
    dispatch(setCheckinTimee(time));
  };

  const handleCheckoutChange = (time: any) => {
    console.log('Selected Check-out Time:', time);
    dispatch(setCheckoutTimee(time));
  };
  
  const handleEarlyCheckinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    console.log('Early Check-in Selected:', isChecked);
    dispatch(setEarlyCheckIn(isChecked));
  };

  const handleEarlyCheckoutChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    console.log('Late Check-out Selected:', isChecked);
   dispatch(setEarlyCheckOut(isChecked))
  };

  return (
    <>
      <RegAppHeader openMenuOverlay={function (): void {
       console.log('function not implemented')
      } }></RegAppHeader>
      

      <Stack gap={2} className="m-[32px]">
        <Typography variant="h3" gutterBottom>
          When do you usually check in and out?
        </Typography>
        <Stack gap={2} direction={"row"}>
          <FrtTimePicker TimepickerLabel="check in" TimepickerValue={handleCheckinChange}></FrtTimePicker>
          <FrtTimePicker TimepickerLabel="check out" TimepickerValue={handleCheckoutChange}></FrtTimePicker>
        </Stack>
        <Stack gap={2} direction={"row"}>
          <FormControlLabel
            control={
              <Checkbox
              value={earlyCheckin}
              onChange={handleEarlyCheckinChange}
                size="small"
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<RadioButtonCheckedIcon />}
              />
            }
            label="early check-in"
            sx={{
              fontSize: 13,
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                value={earlyCheckout}
                onChange={handleEarlyCheckoutChange}
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<RadioButtonCheckedIcon />}
              />
            }
            label="late check-out"
          />
        </Stack>
        <Typography variant="caption">Guests are more inclined to favor properties that allow early check-in and late check-out.</Typography>
        <Button
          variant="contained"
          size="large"
          color="success"
          sx={{
            color: "white",
            borderRadius: "10px",
          }}
          onClick={onButtonClick}
        >
          NEXT
        </Button>
      </Stack>
    </>
  );
};

export default PropCheckInOut;

import { Chip, Stack, Typography, Button, Box } from "@mui/material";
import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import WbSunnyRoundedIcon from "@mui/icons-material/WbSunnyRounded";
import AcUnitRoundedIcon from "@mui/icons-material/AcUnitRounded";
import LocalFloristRoundedIcon from "@mui/icons-material/LocalFloristRounded";
import SpaRoundedIcon from "@mui/icons-material/SpaRounded";
import ThunderstormRoundedIcon from "@mui/icons-material/ThunderstormRounded";
import { styled, ThemeProvider, createTheme } from "@mui/material/styles";

const Seasons = [
  { icon: <WbSunnyRoundedIcon />, label: "Summer", color: "#FF5733" },
  { icon: <AcUnitRoundedIcon />, label: "Winter", color: "#0994CF" },
  { icon: <LocalFloristRoundedIcon />, label: "Spring", color: "#77A306" },
  { icon: <SpaRoundedIcon />, label: "Autumn", color: "#B74C49" },
  { icon: <ThunderstormRoundedIcon />, label: "Mansoon", color: "#323232" },
];

const SeasonChip = styled(Chip)<{ component?: React.ElementType }>({
  "& .MuiChip-icon": {},
});
export default function BestTime({selected}) {
  const navigate = useNavigate();
  const manageBestTime = useCallback(() => {
    navigate("/property-best-season");
  }, [navigate]);
  return (
    <>
      <Stack gap={2} className="mx-6">
        <Stack direction={"row"} className="flex items-center justify-between">
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "medium",
              color: "text.secondary",
            }}
          >
            best time to visit
          </Typography>
          <Button
            variant="outlined"
            size="small"
            sx={{
              lineHeight: "1",
            }}
            onClick={manageBestTime}
          >
            manage
          </Button>
        </Stack>
        <Box>
        { selected && JSON.parse(selected).map((season) => (
            <Chip
              key={season._id}
              icon={<AcUnitRoundedIcon />}
              label={season.name}
              color="primary"
              sx={{
                backgroundColor: "rgba(0,0,0,0)",
                color: "#77A306",
                margin: 1,
              }}
            />
          ))}
        </Box>
      </Stack>
    </>
  );
}

import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SignUpForm from "../components/SignUpForm";
import LegalLink from "../components/LegalLink";
import RegAppHeader from "../components/core/RegAppHeader";
import { Typography, Stack } from "@mui/material";

const NewSignUp: FunctionComponent = () => {
 
  return (
    <>
      <RegAppHeader PageTitle={"SIGN UP"} openMenuOverlay={function (): void {
        console.log('function not implemented')
      } } />
      <Stack
        gap={2}
        direction="column"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{
          mx: 4,
        }}
        className="absolute left-[16px] right-[16px] bottom-[6vh]"
      >
        <Typography variant="h2" color={"text.primary"} gutterBottom>
          Let's <br />
          get started
        </Typography>
        <SignUpForm />
        <Typography variant="body2">
          by “sigining up”, you agree to our{" "}
          <Link to=".." relative="path">
            terms
          </Link>{" "}
          and consent to our{" "}
          <Link to=".." relative="path">
            host policy
          </Link>{" "}
          and{" "}
          <Link to=".." relative="path">
            privacy policy
          </Link>
        </Typography>
        <LegalLink />
      </Stack>
    </>
  );
};

export default NewSignUp;

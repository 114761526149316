import { FunctionComponent, useMemo } from "react";
import CSS, { Property } from "csstype";

type PropertyCardFormType = {
  propertyDimensions?: string;
  sustainableStaysText?: string;
  propertyDescription?: string;

  /** Style props */
  propertiesBoxShadow?: Property.BoxShadow;
  youOwnWithOverflow?: Property.Overflow;
  youOwnWithFlexShrink?: Property.FlexShrink;

  /** Action props */
  onPropertiesContainerClick?: () => void;
};

const PropertyCardForm: FunctionComponent<PropertyCardFormType> = ({
  propertyDimensions,
  sustainableStaysText,
  propertyDescription,
  propertiesBoxShadow,
  youOwnWithOverflow,
  youOwnWithFlexShrink,
  onPropertiesContainerClick,
}) => {
  const campButtonStyle: CSS.Properties = useMemo(() => {
    return {
      boxShadow: propertiesBoxShadow,
    };
  }, [propertiesBoxShadow]);

  const appIconStyle: CSS.Properties = useMemo(() => {
    return {
      overflow: youOwnWithOverflow,
      flexShrink: youOwnWithFlexShrink,
    };
  }, [youOwnWithOverflow, youOwnWithFlexShrink]);

  return (
    <div
      className="self-stretch flex flex-row items-center justify-start gap-[20px] cursor-pointer text-center text-lg text-steelblue-100 font-proza-libre"
      onClick={onPropertiesContainerClick}
    >
      <div
        className="rounded-xl bg-white shadow-[2px_20px_10px_rgba(0,_0,_0,_0.06)] flex flex-row p-5 items-start justify-start border-[4px] border-solid border-silver-200"
        style={campButtonStyle}
      >
        <div
          className="w-[30.37px] h-8 overflow-hidden shrink-0 flex flex-row py-0 px-[3px] box-border items-center justify-center"
          style={appIconStyle}
        >
          <img
            className="relative w-[19.83px] h-6"
            alt=""
            src={propertyDimensions}
          />
        </div>
      </div>
      <div className="flex-1 flex flex-col items-start justify-start gap-[5px]">
        <div className="relative leading-[16px] font-semibold">
          {sustainableStaysText}
        </div>
        <div className="self-stretch relative text-xs leading-[24px] font-open-sans text-left">
          {propertyDescription}
        </div>
      </div>
    </div>
  );
};

export default PropertyCardForm;

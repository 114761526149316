import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import LegalLink from "../components/LegalLink";
import RegAppHeader from "../components/core/RegAppHeader";
import { Link } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import VerifyOTPForm from "../components/VerifyOTPForm";

const VerifyOTP: FunctionComponent = () => {
  return (
    <>
      <RegAppHeader PageTitle={"VERIFY OTP"} openMenuOverlay={function (): void {
       console.log('function not implemented')
      } } />
      <Stack
        gap={2}
        className="absolute bottom-[8vh] p-4 right-4 left-4 text-steelblue-100"
      >
        <Typography variant="h3" gutterBottom>
          Verify <br /> one time password
        </Typography>
        <Typography variant="body1" gutterBottom>
          an OTP (one time password) has been sent to mobile number{" "}
          <b>{localStorage.getItem('mobile')}</b>
        </Typography>
        <Typography variant="body1">
          Wromg Number? <Link to="/login">Go back to change.</Link>{" "}
        </Typography>
        <VerifyOTPForm></VerifyOTPForm>
        <LegalLink />
      </Stack>
    </>
  );
};

export default VerifyOTP;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Chips from "./core/Chips";
import { Stack, Button } from "@mui/material";
import { useDispatch } from 'react-redux';
import { selectLanguage } from '../Redux/Action';

const LanguageFilter = ({ languages }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onButtonClick = () => {
    // Create an array of selected language objects with ID and name
    const selectedLanguages = selectedChips.map((id: any) => ({
      id,
      name: languages.find((lang: { _id: any; }) => lang._id === id).name
    }))

    // Store the selected languages in Redux
    dispatch(selectLanguage(selectedLanguages));

    // Save selected languages to localStorage
    localStorage.setItem('languages', JSON.stringify(selectedLanguages));

    navigate("/profile");
  };

  const [selectedChips, setSelectedChips] = useState<any>([]);

  const toggleChip = (id: any) => {
    if (selectedChips.includes(id)) {
      setSelectedChips(selectedChips.filter((item: any) => item !== id));
    } else {
      setSelectedChips([...selectedChips, id]);
    }
  };

  return (
    <Stack gap={2}>
      <div className="flex gap-[8px] flex-wrap">
        {languages.map((lang: any) => (
          <Chips key={lang._id} text={lang.name} id={lang._id} handleSelected={() => toggleChip(lang._id)} />
        ))}
      </div>
      <Button
        variant="contained"
        size="large"
        color="success"
        sx={{
          color: "white",
          borderRadius: "10px",
        }}
        onClick={onButtonClick}
      >
        NEXT
      </Button>
    </Stack>
  );
};

export default LanguageFilter;

import { FunctionComponent, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import RegAppHeader from "../components/core/RegAppHeader";
import { useDispatch } from "react-redux";
import  {PropertyName}  from "../Redux/Action";

const PropName: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch(); 
  const [propertyName, setPropertyName]=useState<any>('');
  const onButtonClick = async () => {
    dispatch(PropertyName(propertyName));
    await localStorage.setItem('propertyName',propertyName);
    navigate("/best-season");
  }

  return (
    <>
    <RegAppHeader openMenuOverlay={function (): void {
        console.log('function not implemented')
      } }></RegAppHeader>
    <Stack gap={4} p={4}>
        <Box>
          <Typography variant="h5">Property Name</Typography>
        </Box>
        <TextField
          label={" name"}
          placeholder="Only show in Edit "
          variant="outlined"
          onChange={(event:any)=>setPropertyName(event.target.value)}
          value={propertyName}
          sx={{
            borderRadius: "10px",
          }}
        ></TextField>
        </Stack>
    <Button
      variant="contained"
      size="large"
      color="success"
      sx={{
        color: "white",
        borderRadius: "10px",
      }}
      onClick={onButtonClick}
    >
      NEXT
    </Button>
    </>
    
  );
};

export default PropName;

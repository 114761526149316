import { FunctionComponent, useCallback } from "react";
import { Typography, Box, Stack, Button } from "@mui/material";
import EventCard from "../core/EventCard";

export default function PropEvents() {
  return (
    <>
      <Stack
          direction={"row"}
          className="flex items-center justify-between mx-6"
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "medium",
              color: "text.secondary",
            }}
          >
            happening experience
          </Typography>
          <Button
            variant="outlined"
            size="small"
            sx={{
              lineHeight: "1",
            }}
          >
            add event
          </Button>
        </Stack>
      <Box className="overflow-auto w-[100vw] flex nowrap pb-5">
        <EventCard
          name="Hike to Mulkarkha from Chnadratal"
          img="https://source.unsplash.com/random/?camping?order_by=curated&orientation=landscape&license=free&3"
          price="2000"
          startdate="Fri 4th Apr"
          enddate="Sat 5th Apr"
          duration="3N 4D"
        />
        <EventCard
          name="Hike to Mulkarkha from Chnadratal"
          img="https://source.unsplash.com/random/?camping?order_by=curated&orientation=landscape&license=free&3"
          price="2000"
          startdate="Fri 4th Apr"
          enddate="Sat 5th Apr"
          duration="3N 4D"
        />
      </Box>
    </>
  );
}

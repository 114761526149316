import { FunctionComponent, useCallback } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import PropertyCardForm from "./PropertyCardForm";

const HostFormContainer: FunctionComponent = () => {
  const navigate = useNavigate();
  const onPropertiesContainerClick = useCallback(() => {
        navigate("/property-location");
  }, [Navigate]);

  const onExperienceContainerClick = useCallback(() => {
    // Please sync "Add Experience 01" to the project
  }, []);

  const onConveyenceContainerClick = useCallback(() => {
    // Please sync "Conveyence 3" to the project
  }, []);

  return (
    <div className="self-stretch flex flex-col items-center justify-center gap-[40px] text-center text-lg text-steelblue-100 font-proza-libre">
      <PropertyCardForm
        propertyDimensions="/vector.svg"
        sustainableStaysText="Properties"
        propertyDescription="you own with sustainable, eco freindly stays."
        onPropertiesContainerClick={onPropertiesContainerClick}
      />
      <PropertyCardForm
        propertyDimensions="/subtract1.svg"
        sustainableStaysText="Experiences"
        propertyDescription="Outdoor experiences you provide in your or others property."
        propertiesBoxShadow="2px 10px 10px rgba(0, 0, 0, 0.06)"
        youOwnWithOverflow="unset"
        youOwnWithFlexShrink="unset"
        onPropertiesContainerClick={onExperienceContainerClick}
      />
      <div
        className="self-stretch flex flex-row items-center justify-start gap-[20px] cursor-pointer"
        onClick={onConveyenceContainerClick}
      >
        <div className="rounded-xl bg-white shadow-[2px_10px_10px_rgba(0,_0,_0,_0.06)] flex flex-row p-5 items-start justify-start border-[4px] border-solid border-silver-200">
          <img
            className="relative w-8 h-8 overflow-hidden shrink-0"
            alt=""
            src="/icons9.svg"
          />
        </div>
        <div className="flex-1 flex flex-col items-start justify-start gap-[5px]">
          <div className="relative leading-[16px] font-semibold">
            Conveyance
          </div>
          <div className="self-stretch relative text-xs leading-[24px] font-open-sans text-left">
            pickup/drop or local sightseeing service
          </div>
        </div>
      </div>
    </div>
  );
};

export default HostFormContainer;

import { FunctionComponent, useMemo } from "react";
import CSS, { Property } from "csstype";

type TypemonogramIconType = {
  typemonogramIconTypemonog?: string;

  /** Style props */
  typemonogramIconPosition?: Property.Position;
  typemonogramIconWidth?: Property.Width;
  typemonogramIconHeight?: Property.Height;
  typemonogramIconTop?: Property.Top;
  typemonogramIconRight?: Property.Right;
  typemonogramIconBottom?: Property.Bottom;
  typemonogramIconLeft?: Property.Left;
  typemonogramIconMaxWidth?: Property.MaxWidth;
  typemonogramIconOverflow?: Property.Overflow;
  typemonogramIconMaxHeight?: Property.MaxHeight;
};

const TypemonogramIcon: FunctionComponent<TypemonogramIconType> = ({
  typemonogramIconTypemonog,
  typemonogramIconPosition,
  typemonogramIconWidth,
  typemonogramIconHeight,
  typemonogramIconTop,
  typemonogramIconRight,
  typemonogramIconBottom,
  typemonogramIconLeft,
  typemonogramIconMaxWidth,
  typemonogramIconOverflow,
  typemonogramIconMaxHeight,
}) => {
  const typemonogramIconStyle: CSS.Properties = useMemo(() => {
    return {
      position: typemonogramIconPosition,
      width: typemonogramIconWidth,
      height: typemonogramIconHeight,
      top: typemonogramIconTop,
      right: typemonogramIconRight,
      bottom: typemonogramIconBottom,
      left: typemonogramIconLeft,
      maxWidth: typemonogramIconMaxWidth,
      overflow: typemonogramIconOverflow,
      maxHeight: typemonogramIconMaxHeight,
    };
  }, [
    typemonogramIconPosition,
    typemonogramIconWidth,
    typemonogramIconHeight,
    typemonogramIconTop,
    typemonogramIconRight,
    typemonogramIconBottom,
    typemonogramIconLeft,
    typemonogramIconMaxWidth,
    typemonogramIconOverflow,
    typemonogramIconMaxHeight,
  ]);

  return (
    <img
      className="relative w-[20.95px] h-[20.51px]"
      alt=""
      src={typemonogramIconTypemonog}
      style={typemonogramIconStyle}
    />
  );
};

export default TypemonogramIcon;

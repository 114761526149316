import { FunctionComponent, useCallback } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import "./ribbon.css";
export default function ExpCard(props) {
  return (
    <Card
      sx={{
        minWidth: 160,
        padding: 1,
        borderRadius: 2,
        position: "relative",
        mx: "4px",
      }}
    >
      <CardMedia
        component="img"
        alt={props.name}
        height="160"
        image={props.img}
        sx={{
          borderRadius: 2,
          boxShadow: 4,
        }}
      />
      <div className="priceDetails">
        <p className="ribbon">
          <span className="text">
            <strong className="bold">
              <CurrencyRupeeIcon
                sx={{
                  fontSize: "18px",
                }}
              />
            </strong>
            {props.price}/-
          </span>
        </p>
        <small className="badge bg-dark align-self-end me-3 duration">
          {props.duration}
        </small>
      </div>
      <CardContent>
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          sx={{ fontSize: "16px" }}
        >
          {props.name}
        </Typography>
      </CardContent>
    </Card>
  );
}

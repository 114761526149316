import { createTheme } from "@mui/material";

export const FrtTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#575a89",
    },
    secondary: {
      main: "#ff5733",
    },
    text: {
      primary: "#575a89",
      secondary: "#c4c4c4",
      disabled: "#f1f1f1",
    },
    background: {
      paper: "#ffffff",
      default: "#f5f5f5",
    },
    error: {
      main: "#ff1744",
    },
    warning: {
      main: "#ff3d00",
    },
    info: {
      main: "#40c4ff",
    },
    success: {
      main: "#93bd33",
    },
  },
  typography: {
    fontFamily: "Open Sans",
    fontSize: 14,
    h1: {
      fontSize: "2.5rem",
      fontWeight: 400,
      fontFamily: "Proza Libre",
      color: "#575a89",
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 600,
      fontFamily: "Proza Libre",
      color: "#575a89",
    },
    h3: {
      fontFamily: "Proza Libre",
      fontSize: "1.5rem",
      color: "#575a89",
    },
    h4: {
      fontSize: "1.4rem",
      fontFamily: "Proza Libre",
      color: "#575a89",
    },
    h5: {
      fontSize: "1.3rem",
      fontFamily: "Proza Libre",
      color: "#575a89",
    },
    h6: {
      fontSize: "1.2rem",
      fontFamily: "Proza Libre",
      color: "#575a89",
    },
    subtitle1: {
      fontSize: "1rem",
      color: "#575a89",
    },
    subtitle2: {
      fontSize: "0.9rem",
      color: "#575a89",
    },
    body1: {
      fontSize: "1rem",
      lineHeight: 1.8,
      color: "#575a89",
    },
    body2: {
      fontSize: "0.8rem",
      lineHeight: 1.8,
      color: "#575a89",
    },
    button: {
      fontFamily: "Proza Libre",
      fontSize: "1.2rem",
      fontWeight: 600,
      lineHeight: 3,
    },
    caption: {
      fontSize: "0.8rem",
      lineHeight: 1.6,
      color: "#575a89",
    },
  },
});

import { SELECT_LANGUAGE } from "./ActionTypes";

const initialState = {
    selectedImage: null,
    location: "",
    locationName: "",
    propertyName: "",
    selectedSeasons: [],
    selectedTerrians: [],
    selectedAmenities:[],
    earlyCheckin: false,
    earlyCheckout: false,
    checkinTime: '10:00 AM',
  checkoutTime: '10:00 AM',
  stayName: "",
  regularPrice: 0,
  regularPricePerPerson: 0,
  weekendPrice: 0,
  weekendPricePerPerson: 0,
  foodPrice: [],
  foodPriceIncluded:[],
  facilitiesPrice:[],
  facilitiesPriceIncluded:[],
  selectedValueAdded: [],
  selectedMedia: [],
  campRules: '',
  cancep:''

  };

export const languageReducer = (state = null, action: { type: any; payload: any; }) => {
    switch (action.type) {
      case SELECT_LANGUAGE:
        return action.payload;
      default:
        return state;
    }
  };
  
export const imageReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
      case 'UPLOAD_IMAGE':
        return {
          ...state,
          selectedImage: action.payload, // Update the selectedImage with the new value
        };
        case 'REMOVE_IMAGE':
            return {
              ...state,
              selectedImage: null, // Update the selectedImage with the new value
            };
      default:
        return state;
    } 
}

export const locationReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
      case 'SET_LOCATION':
        return {
          ...state,
          location: action.payload,
        };
      default:
        return state;
    }
  };

  export const locationNameReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
      case 'SET_LOCATION_NAME':
        return {
          ...state,
          locationName: action.payload,
        };
      default:
        return state;
    }
  };
  
  export const propertyNameReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
      case 'SET_PROPERTY_NAME':
        return {
          ...state,
          propertyName: action.payload,
        };
      default:
        return state;
    }
  };

  export const seasonsReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
      case 'SET_SELECTED_SEASONS':
        return {
          ...state,
          selectedSeasons: action.payload,
        };
      default:
        return state;
    }
  };

  export const terriansReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
      case 'SET_SELECTED_TERRIANS':
        return {
          ...state,
          selectedTerrians: action.payload,
        };
      default:
        return state;
    }
  };


  export const amenitiesReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
      case 'SET_SELECTED_AMENITIES':
        return {
          ...state,
          selectedAmenities: action.payload,
        };
      default:
        return state;
    }
  };

  export const earlyCheckReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
      case 'SET_EARLY_CHECKIN':
        return {
          ...state,
          earlyCheckin: action.payload,
        };
      case 'SET_EARLY_CHECKOUT':
        return {
          ...state,
          earlyCheckout: action.payload,
        };
      default:
        return state;
    }
  };

  export const timeReducer = (state = initialState, action: { type: any; payload: any; }) => {
    console.log('nnntime',action.payload, )
    switch (action.type) {
      case 'SET_CHECKIN_TIME':
        return {
          ...state,
          checkinTime: action.payload,
        };
      case 'SET_CHECKOUT_TIME':
        return {
          ...state,
          checkoutTime: action.payload,
        };
      default:
        return state;
    }
  };

  export const stayNameReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
      case 'SET_STAY_NAME':
        return {
          ...state,
          stayName: action.payload,
        };
      default:
        return state;
    }
  };

  export const countReducer = (state = 0, action: { type: any; payload: any; }) => {
    switch (action.type) {
      case 'SET_COUNT':
        return action.payload;
      default:
        return state;
    }
  };

  export const maxPersonsReducer = (state = 0, action: { type: any; payload: any; }) => {
    switch (action.type) {
      case 'SET_MAX_PERSONS':
        return action.payload;
      default:
        return state;
    }
  };

  export const regularPricingReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
      case 'SET_REGULAR_PRICE':
        return {
          ...state,
          regularPrice: action.payload,
        };
      case 'SET_REGULAR_PRICE_PER_PERSON':
        return {
          ...state,
          regularPricePerPerson: action.payload,
        };
      default:
        return state;
    };
  }

  export const weekendPricingReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
      case 'SET_WEEKEND_PRICE':
        return {
          ...state,
          weekendPrice: action.payload,
        };
      case 'SET_WEEKEND_PRICE_PER_PERSON':
        return {
          ...state,
          weekendPricePerPerson: action.payload,
        };
      default:
        return state;
    };
  }

  export const PriceFoodReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
      case 'SET_FOOD':
        return {
          ...state,
          foodPrice: action.payload,
        };
      case 'SET_FOOD_INCLUDED':
        return {
          ...state,
          foodPriceIncluded: action.payload,
        };
      default:
        return state;
    };
  }

  export const PriceFacilitiesReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
      case 'SET_FECILITIES':
        return {
          ...state,
          facilitiesPrice: action.payload,
        };
      case 'SET_FECILITIES_INCLUDED':
        return {
          ...state,
          facilitiesPriceIncluded: action.payload,
        };
      default:
        return state;
    };
  }

  export const valueAddedReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
      case 'SET_VALUE_ADDED':
        return {
          ...state,
          selectedValueAdded: action.payload,
        };
      default:
        return state;
    }
  };

  export const imageMediaReducer = (state = initialState, action: { type: any; payload: any; }) => {
    console.log('reducerrr',action.payload)
    switch (action.type) {
      case 'UPLOAD_MEDIA':
        return {
          ...state,
          selectedMedia: action.payload,
        };
      default:
        return state;
    } 
  };

export const Rulesreducer = (state = initialState, action: { type: any; payload: any; }) => {
  switch (action.type) {
    case 'SET_CAMP_RULES':
      return {
        ...state,
        campRules: action.payload,
      };
    default:
      return state;
  }
};

export const CancellPreducer = (state = initialState, action: { type: any; payload: any; }) => {
  switch (action.type) {
    case 'SET_CANCELLATION_P':
      return {
        ...state,
        cancep: action.payload,
      };
    default:
      return state;
  }
};


import { FunctionComponent, useState, useCallback } from "react";
import MenuOverlay from "../components/MenuOverlay";
import PortalPopup from "../components/PortalPopup";
import HostFormContainer from "../components/HostFormContainer";
import RegAppHeader from "../components/core/RegAppHeader";
import { Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
// import { RootState } from '../Redux/Reducer';

export interface RootState {
  campRules: any;
  image: {
    selectedImage: '' | null; // Adjust the type based on your actual image data type
    // Add other properties if needed
  };
  // Add other slices of your state if applicable
}

const StartHosting: FunctionComponent = () => {
//   const uploadedImage = useSelector((state: RootState) => state?.image?.selectedImage);
//   const imageUrl = uploadedImage ? URL.createObjectURL(uploadedImage) : '';

//   console.log('imageUrl',imageUrl)

// console.log('uploadedImage',uploadedImage)
  const [isMenuOverlayOpen, setMenuOverlayOpen] = useState(false);

  const onPropertiesContainerClick = useCallback(() => {
    // Please sync "Add Property 01" to the project
  }, []);

  const onExperienceContainerClick = useCallback(() => {
    // Please sync "Add Experience 01" to the project
  }, []);

  const openMenuOverlay = useCallback(() => {
    setMenuOverlayOpen(true);
  }, []);

  const closeMenuOverlay = useCallback(() => {
    setMenuOverlayOpen(false);
  }, []);

  

  return (
    <>
      <RegAppHeader
      openMenuOverlay={openMenuOverlay}
      ></RegAppHeader>
      <Stack className="absolute left-[32px] right-[32px] bottom-[10vh]" gap={4}>
      {/* {imageUrl && <img src={imageUrl} alt="Uploaded" style={{ height: '200px', width: '300px' }}  />} */}
        <Typography
          variant="h3"
          sx={{ color: "#575A89", fontWeight: "600" }}
          gutterBottom
        >
          What do you <br /> want to host?
        </Typography>
        <HostFormContainer />
      </Stack>

      {isMenuOverlayOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeMenuOverlay}
        >
          <MenuOverlay onClose={closeMenuOverlay} />
        </PortalPopup>
      )}
    </>
  );
};

export default StartHosting;

import { Typography, Stack, Divider, Button } from "@mui/material";
import * as React from "react";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import PolicyCard from "../core/PolicyCard";
import NearBy from "../core/NearBy";


export default function PropNearBy() {
  return (
    <Stack gap={4}>
      <Stack direction={"row"} className="flex items-center justify-between mx-6">
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: "medium",
            color: "text.secondary",
          }}
        >
          near by places
        </Typography>
        <Button
          variant="outlined"
          size="small"
          sx={{
            lineHeight: "1",
          }}
        >
          manage
        </Button>
      </Stack>
      <Stack
            direction={"row"}
            className="overflow-auto w-[100vw] flex nowrap pb-5 items-start"
          >
            <NearBy
              name="Pobitora Wildlife Santuary"
              img="https://source.unsplash.com/random/?camping?order_by=curated&orientation=landscape&license=free&2"
            />
            <NearBy
              name="Mandarmoni"
              img="https://source.unsplash.com/random/?camping?order_by=curated&orientation=landscape&license=free&5"
            />
            <NearBy
              name="Talshari"
              img="https://source.unsplash.com/random/?camping?order_by=curated&orientation=landscape&license=free&3"
            />
            <NearBy
              name="odisaa eco park"
              img="https://source.unsplash.com/random/?camping?order_by=curated&orientation=landscape&license=free&8"
            />
            <NearBy
              name="Pobitora"
              img="https://source.unsplash.com/random/?camping?order_by=curated&orientation=landscape&license=free&9"
            />
            <NearBy
              name="Pobitora"
              img="https://source.unsplash.com/random/?camping?order_by=curated&orientation=landscape&license=free&11"
            />
          </Stack>
    </Stack>
  );
}

import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Stack, Button, Typography } from "@mui/material";

import OtpInput from "./core/OtpInput";

const VerifyOTPForm: FunctionComponent = () => {
  const navigate = useNavigate();
const [counter,setCounter]=useState(119);
useEffect(()=>{
  if(counter!=0){
  setTimeout(()=>{
    setCounter(counter-1);
  },1000);
}
},[counter])
  const onButtonClick = useCallback(() => {
    navigate("/languages");
  }, [navigate]);

  return (
    <Stack
      gap={2}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <OtpInput />
      <Button
        variant="contained"
        size="large"
        color="success"
        sx={{
          color: "white",
          borderRadius: "10px",
        }}
        fullWidth
        onClick={onButtonClick}
      >
        VERIFY
      </Button>
      <Typography variant="body1"> Resend OTP in {counter}</Typography>
    </Stack>
  );
};

export default VerifyOTPForm;

import { FunctionComponent, useCallback } from "react";
import {
  Card,
  Icon,
  Typography,
  Paper,
  Box,
  Stack,
  CardMedia,
} from "@mui/material";

export default function NearBy(props) {
  return (
    <>
      <Box className="w-[144px] flex flex-col justify-center gap-2 m-2">
        <Card
          className="bg-white p-1 w-[144px]"
          sx={{
            borderRadius: "16px",
            boxShadow: 8,
          }}
        >
          <CardMedia
            component="img"
            alt={props.name}
            height="104"
            image={props.img}
            sx={{
              borderRadius: "16px",
            }}
          />
        </Card>
        <Typography variant="caption" className="text-center">
          {props.name}
        </Typography>
      </Box>
    </>
  );
}

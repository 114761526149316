import React, { useState } from "react";
import { Stack } from "@mui/material";

const NumberInput = ({ count, onChange }) => {
  const incrementCount = () => {
    onChange(count + 1);
  };

  const decrementCount = () => {
    if (count > 0) {
      onChange(count - 1);
    }
  };

  return (
    <Stack
      direction="row"
      sx={{
        border: 1,
        borderColor: "primary.main",
      }}
      className="rounded-lg h-[30px] overflow-hidden min-w-[82px]"
    >
      <button onClick={incrementCount} className="text-lg ps-3">
        +
      </button>
      <input
        name="count"
        value={count}
        className="w-[20px] p-1 text-center"
        readOnly
      ></input>
      <button onClick={decrementCount} className="text-lg ps-3">
        -
      </button>
    </Stack>
  );
};

export default NumberInput;

import { combineReducers } from 'redux';
import { imageReducer, languageReducer, locationReducer, locationNameReducer, propertyNameReducer, seasonsReducer, terriansReducer, amenitiesReducer, earlyCheckReducer, stayNameReducer, countReducer, maxPersonsReducer, regularPricingReducer, weekendPricingReducer, PriceFoodReducer, PriceFacilitiesReducer, valueAddedReducer, imageMediaReducer, Rulesreducer, CancellPreducer } from './Reducer';

// Import other reducers if needed

const rootReducer = combineReducers({
  selectedLanguages: languageReducer,
  image: imageReducer,
  location: locationReducer,
  locationName: locationNameReducer,
  propertyName: propertyNameReducer,
  selectedSeasons: seasonsReducer ,
 selectedTerrains: terriansReducer,
 selectedAmenities: amenitiesReducer,
 selectedCheckout: earlyCheckReducer,
 selectedStay: stayNameReducer,
 selectedCount: countReducer,
 selectedMax: maxPersonsReducer,
 selectedregularPrice: regularPricingReducer,
 selectedweekendPrice: weekendPricingReducer,
 selectedfoodprice: PriceFoodReducer,
 selectedfacilitiesprice: PriceFacilitiesReducer,
 selectedValueAdded: valueAddedReducer,
 selectdMedia: imageMediaReducer,
 selectedRules:Rulesreducer,
 selectedCan: CancellPreducer
  // Add other reducers here if needed
});

export default rootReducer;
import { SELECT_LANGUAGE, UPLOAD_IMAGE } from "./ActionTypes";

export const selectLanguage = (selectedLanguage: any) => {
  return {
    type: SELECT_LANGUAGE,
    payload: selectedLanguage,
  };
};

export const uploadImage = (imageData: File) => ({
  type: UPLOAD_IMAGE,
  payload: imageData,
});

export const removeImage = () => ({
  type: "REMOVE_IMAGE",
});

export const setLocations = (location: any) => ({
  type: "SET_LOCATION",
  payload: location,
});

export const setLocationsName = (locationName: any) => ({
  type: "SET_LOCATION_NAME",
  payload: locationName,
});

export const PropertyName = (PropertyName: any) => ({
  type: "SET_PROPERTY_NAME",
  payload: PropertyName,
});

export const setSelectedSeasonss = (selectedSeasons: any) => ({
  type: "SET_SELECTED_SEASONS",
  payload: selectedSeasons,
});

export const setSelectedTerrianss = (selectedTerrians: any) => ({
  type: "SET_SELECTED_TERRIANS",
  payload: selectedTerrians,
});

export const setSelectedAmenitiess = (selectedAmenities: any) => ({
  type: "SET_SELECTED_AMENITIES",
  payload: selectedAmenities,
});

export const setEarlyCheckIn = (isChecked: any) => ({
  type: "SET_EARLY_CHECKIN",
  payload: isChecked,
});

export const setEarlyCheckOut = (isChecked: any) => ({
  type: "SET_EARLY_CHECKOUT",
  payload: isChecked,
});

export const setCheckinTimee = (time: any) => ({
  type: "SET_CHECKIN_TIME",
  payload: time,
});

export const setCheckoutTimee = (time: any) => ({
  type: "SET_CHECKOUT_TIME",
  payload: time,
});

export const setStayNamee = (stay: any) => ({
  type: "SET_STAY_NAME",
  payload: stay,
});

export const setCount = (count: any) => ({
  type: 'SET_COUNT',
  payload: count,
});

export const setMaxPersons = (maxPersons: any) => ({
  type: 'SET_MAX_PERSONS',
  payload: maxPersons,
});

// regularPricingActions.js
export const setRegularPrice = (price: number) => ({
  type: 'SET_REGULAR_PRICE',
  payload: price,
});

export const setWeekendPrice = (price: number) => ({
  type: 'SET_WEEKEND_PRICE',
  payload: price,
});

// perPersonPricingActions.js
export const setRegularPricePerPerson = (price: number) => ({
  type: 'SET_REGULAR_PRICE_PER_PERSON',
  payload: price,
});

export const setWeekendPricePerPerson = (price: number) => ({
  type: 'SET_WEEKEND_PRICE_PER_PERSON',
  payload: price,
});

//excluded in price
export const selectFood = (selectedFood: any) => {
  return {
    type: 'SET_FOOD',
    payload: selectedFood,
  };
};

export const  selectFecilities= (selectedFecilities: any) => {
  return {
    type: 'SET_FECILITIES',
    payload: selectedFecilities
  };
};

//include in price 
export const selectFoodincluded = (selectedincludedFood: any) => {
  return {
    type: 'SET_FOOD_INCLUDED',
    payload: selectedincludedFood,
  };
};

export const  selectFecilitiesincluded = (selectedincludedFecilities: any) => {
  return {
    type: 'SET_FECILITIES_INCLUDED',
    payload: selectedincludedFecilities
  };
};

export const selectValueAdded = (selectedValueAdded: any) => {
  return {
    type: 'SET_VALUE_ADDED',
    payload: selectedValueAdded,
  };
};

export const uploadMedia = (images: any) => {
  console.log('imagggg',images)
  return {
    type: 'UPLOAD_MEDIA',
    payload: images
  };
};

export const setCampRules = (rules: string) => ({
  type: 'SET_CAMP_RULES',
  payload: rules,
});

export const setCancellationP = (cancellationP: string) => ({
  type: 'SET_CANCELLATION_P',
  payload: cancellationP,
});

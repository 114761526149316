import * as React from "react";
import { FunctionComponent, useCallback } from "react";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Box,
  Stack,
  Button,
  Menu,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import AddOnCard from "../core/AddOnCard";
import CabinRoundedIcon from "@mui/icons-material/CabinRounded";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PropAddOns() {
  const navigate = useNavigate();
  const onButtonClick = useCallback(() => {
    navigate("/stay-details");
  }, [navigate]);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Stack
        direction={"row"}
        className="flex items-center justify-between mx-6"
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: "medium",
            color: "text.secondary",
          }}
        >
          add ons
        </Typography>
        <Button
          variant="outlined"
          size="small"
          sx={{
            lineHeight: "1",
          }}
          onClick={handleClickOpen}
        >
          add AddOn
        </Button>
      </Stack>
      <Box className="overflow-auto w-[100%] flex nowrap pb-5">
        <AddOnCard
          name="Pick up from NJP"
          description="by Alto, Sumo, or similar"
          price="8000"
          minperson="01"
          maxperson="04"
        />
        <AddOnCard
          name="Pick up from NJP"
          description="by Alto, Sumo, or similar"
          price="8000"
          minperson="01"
          maxperson="04"
        />
      </Box>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle>
          <Stack
            direction={"row"}
            className="flex items-center justify-between"
          >
            <Typography variant="h6">add on type</Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <MenuList>
            <MenuItem
              sx={{
                border: 1,
                borderRadius: 4,
                mb: "8px",
              }}
              onClick={onButtonClick}
            >
              <ListItemIcon>
                <CabinRoundedIcon style={{ color: "#575A89" }} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography
                    variant="h6"
                    style={{ color: "#575A89", lineHeight: "14px" }}
                  >
                    Transport
                  </Typography>
                }
                secondary={
                  <Typography variant="caption" style={{ color: "#6f6f6f" }}>
                    Pickup/Drop, Sightseeing etc
                  </Typography>
                }
                style={{ margin: "16px 0 8px 8px" }}
              />

              <Typography variant="body2">
                <NavigateNextRoundedIcon></NavigateNextRoundedIcon>
              </Typography>
            </MenuItem>
            <MenuItem
              sx={{
                border: 1,
                borderRadius: 4,
                mb: "8px",
              }}
            >
              <ListItemIcon>
                <CabinRoundedIcon style={{ color: "#575A89" }} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography
                    variant="h6"
                    style={{ color: "#575A89", lineHeight: "14px" }}
                  >
                    Guide/ Porter
                  </Typography>
                }
                secondary={
                  <Typography variant="caption" style={{ color: "#6f6f6f" }}>
                    Guides or Porter for activity
                  </Typography>
                }
                style={{ margin: "16px 0 8px 8px" }}
              />

              <Typography variant="body2">
                <NavigateNextRoundedIcon></NavigateNextRoundedIcon>
              </Typography>
            </MenuItem>
            <MenuItem
              sx={{
                border: 1,
                borderRadius: 4,
                mb: "8px",
              }}
            >
              <ListItemIcon>
                <CabinRoundedIcon style={{ color: "#575A89" }} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography
                    variant="h6"
                    style={{ color: "#575A89", lineHeight: "14px" }}
                  >
                    Equipment Rental
                  </Typography>
                }
                secondary={
                  <Typography variant="caption" style={{ color: "#6f6f6f" }}>
                    equipments guest can rent
                  </Typography>
                }
                style={{ margin: "16px 0 8px 8px" }}
              />

              <Typography variant="body2">
                <NavigateNextRoundedIcon></NavigateNextRoundedIcon>
              </Typography>
            </MenuItem>
            <MenuItem
              sx={{
                border: 1,
                borderRadius: 4,
                mb: "8px",
              }}
            >
              <ListItemIcon>
                <CabinRoundedIcon style={{ color: "#575A89" }} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography
                    variant="h6"
                    style={{ color: "#575A89", lineHeight: "14px" }}
                  >
                    Other
                  </Typography>
                }
                secondary={
                  <Typography variant="caption" style={{ color: "#6f6f6f" }}>
                    add new add on
                  </Typography>
                }
                style={{ margin: "16px 0 8px 8px" }}
              />

              <Typography variant="body2">
                <NavigateNextRoundedIcon></NavigateNextRoundedIcon>
              </Typography>
            </MenuItem>
          </MenuList>
        </DialogContent>
      </Dialog>
    </>
  );
}

import Card from "@mui/material/Card";

import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

import "./ribbon.css";

import { Box } from "@mui/material";

import CabinRoundedIcon from "@mui/icons-material/CabinRounded";
import CurrencyRupeeRoundedIcon from "@mui/icons-material/CurrencyRupeeRounded";

export default function StsyCard(props) {
  return (
    <Box className="relative h-[240px]">
      <Card
        sx={{
          minWidth: 224,
          padding: 1,
          borderRadius: 2,
          position: "relative",
          mx: "8px",
        }}
      >
        <CardMedia
          component="img"
          alt={props.name}
          height="140"
          image={props.img}
          sx={{
            borderRadius: 2,
            boxShadow: 4,
          }}
        />
      </Card>
      <div className="absolute top-[112px] left-[32px] right-[32px] bg-white rounded-lg shadow-2xl">
        <div className="flex flex-row items-center p-4">
          
          {/* this icons should load dynamically  */}

          <CabinRoundedIcon
            className="me-3"
            sx={{
              color: "#575A89",
            }}
          />
          <div>
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: "600",
              }}
            >
              {props.name}
            </Typography>
            <div className="flex flex-row items-center">
              <CurrencyRupeeRoundedIcon
                sx={{
                  fontSize: "14px",
                  color: "#FF6666",
                }}
              />
              <Typography
                variant="caption"
                className="font-bold"
                sx={{
                  color: "#FF6666",
                  fontWeight: "600",
                }}
              >
                {props.price}
              </Typography>
              <Typography variant="caption">/{props.pricetype}</Typography>
            </div>
          </div>
        </div>
      </div>
      <Typography
        variant="caption"
        sx={{
          textAlign: "center",
          color: "#FF6666",
        }}
        className="pt-10 block"
      >
        {" "}
        {props.available} available !
      </Typography>
    </Box>
  );
}

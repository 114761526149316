import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Box } from "@mui/material";

type ChipsType = {
  text?: string;
  id?: string;
  handleSelected: ()=> void;
};

const formControlLabelStyle = {
  "& .MuiFormControlLabel-label": {
    fontSize: "0.9rem",
    fontWeight: "600",
  },
};


const Chips: React.FunctionComponent<any> = ({ text, id, handleSelected}) => {
  
  const [selectedChips, setSelectedChips] = React.useState<any>([]);  
  const toggleChip = ( id:any ) => {
    if (selectedChips.includes(id)) {
      setSelectedChips(selectedChips.filter((item:any) => item !== id));
    }else{
      selectedChips.push(id);
      setSelectedChips([...selectedChips]);
    }
    handleSelected(id);
    }
  return (
    <Box className={'rounded-8xs font-proza-libre border-[0.5px] border-solid border-lightgray px-2 font-bold '  }  sx={{ bgcolor: selectedChips.includes(id) ? 'warning.main' : '' }}>
      <FormControlLabel
        control={
          <Checkbox
            inputProps={{ "aria-label": "controlled" }}
            icon={<AddRoundedIcon />}
            checkedIcon={<CloseRoundedIcon />}
            size="small"
            onClick={()=>{toggleChip(id)}}
          />
        }
        label={ text}
        value={text}
        sx={{ ...formControlLabelStyle}}
      />
    </Box>
  );
};

export default Chips;

import * as React from "react";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import {
  Stack,
  Typography,
  Button,
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  TextField,
  InputAdornment,
  Divider,
} from "@mui/material";
import LocalAirportRoundedIcon from "@mui/icons-material/LocalAirportRounded";
import TrainRoundedIcon from "@mui/icons-material/TrainRounded";
import LocalTaxiRoundedIcon from "@mui/icons-material/LocalTaxiRounded";
import CloseIcon from "@mui/icons-material/Close";
import DirectionsRoundedIcon from '@mui/icons-material/DirectionsRounded';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DistanceFrom() {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Stack gap={2} className="mx-6">
        <Stack direction={"row"} className="flex items-center justify-between">
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "medium",
              color: "text.secondary",
            }}
          >
            distance from
          </Typography>
          <Button
            variant="outlined"
            size="small"
            sx={{
              lineHeight: "1",
            }}
            onClick={handleClickOpen}
          >
            manage
          </Button>
        </Stack>
        <Stack className="m-5 " gap={1}>
          <Typography variant="body1" className="flex center">
            <LocalAirportRoundedIcon className="me-2" />
            Bagdogra | 80KM
          </Typography>
          <Typography variant="body1" className="flex center">
            <TrainRoundedIcon className="me-2" />
            New Jalpaiguri | 100KM
          </Typography>
          <Typography variant="body1" className="flex center">
            <LocalTaxiRoundedIcon className="me-2" />
            Algarah | 20KM
          </Typography>
        </Stack>
      </Stack>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle>
          <Stack
            direction={"row"}
            className="flex items-center justify-between"
          >
            <Typography variant="h6">manage distances</Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            <TextField
              label="nearest airport"
              placeholder="type to find the airport"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocalAirportRoundedIcon
                      sx={{
                        opacity: "0.4",
                      }}
                    />
                  </InputAdornment>
                ),
                style: {
                  borderRadius: "10px",
                },
              }}
              variant="outlined"
              fullWidth
              sx={{ marginTop: "20px" }}
            />
            <TextField
              label="distance"
              placeholder="distance in KM"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DirectionsRoundedIcon
                      sx={{
                        opacity: "0.4",
                      }}
                    />
                  </InputAdornment>
                ),
                style: {
                  borderRadius: "10px",
                },
              }}
              variant="outlined"
              fullWidth
            />
            <Divider></Divider>
            <TextField
              label="nearest railway station"
              placeholder="type to find the railway station"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <TrainRoundedIcon
                      sx={{
                        opacity: "0.4",
                      }}
                    />
                  </InputAdornment>
                ),
                style: {
                  borderRadius: "10px",
                },
              }}
              variant="outlined"
              fullWidth
              sx={{ marginTop: "20px" }}
            />
            <TextField
              label="distance"
              placeholder="distance in KM"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DirectionsRoundedIcon
                      sx={{
                        opacity: "0.4",
                      }}
                    />
                  </InputAdornment>
                ),
                style: {
                  borderRadius: "10px",
                },
              }}
              variant="outlined"
              fullWidth
            />
            <Divider></Divider>
            <TextField
              label="nearest bus/taxi station"
              placeholder="type the name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocalTaxiRoundedIcon
                      sx={{
                        opacity: "0.4",
                      }}
                    />
                  </InputAdornment>
                ),
                style: {
                  borderRadius: "10px",
                },
              }}
              variant="outlined"
              fullWidth
              sx={{ marginTop: "20px" }}
            />
            <TextField
              label="distance"
              placeholder="distance in KM"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DirectionsRoundedIcon
                      sx={{
                        opacity: "0.4",
                      }}
                    />
                  </InputAdornment>
                ),
                style: {
                  borderRadius: "10px",
                },
              }}
              variant="outlined"
              fullWidth
            />
            <Button
          variant="contained"
          size="large"
          color="success"
          sx={{
            color: "white",
            borderRadius: "10px",
          }}
          onClick={handleClose}
        >
          update
        </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}

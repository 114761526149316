import { FunctionComponent, useCallback } from "react";
import { makeStyles } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ClassNames } from "@emotion/react";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import "./ribbon.css";
import { relative } from "path/posix";
import { Avatar, Divider, Stack, Box } from "@mui/material";
import DirectionsCarFilledRoundedIcon from "@mui/icons-material/DirectionsCarFilledRounded";
import NumberInput from "./NumberInput";

export default function HostCard(props) {
  return (
    <Card
      sx={{
        padding: 1,
        borderRadius: 4,
        mx: "16px",
      }}
    >
      <CardContent>
        <Stack gap={2}>
          <Stack
            direction={"row"}
            className="flex justify-between items-center"
          >
            <Stack
              direction={"row"}
              className="flex justify-between items-center"
            >
              <Avatar />
              <Box>
                <Typography
                  variant="subtitle1"
                  className="px-4"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  {props.hostname}
                </Typography>
                <Typography variant="caption" className="px-4">
                  Member Since {props.startdate}
                </Typography>
              </Box>
            </Stack>

            <Button variant="contained" size="small" sx={{
              padding: '8px',
              lineHeight: '1'
            }}>
              message
            </Button>
          </Stack>
          <Typography variant="caption" gutterBottom sx={{
            fontWeight: 'bold',
          }}>
            {props.replyrate} reply rate, can speak {props.languages}{" "}
          </Typography>
          <Typography variant="caption" gutterBottom>
            {props.bio}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}

import { FunctionComponent, SetStateAction, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RegAppHeader from "../components/core/RegAppHeader";
import {
  Stack,
  Typography,
  Box,
  TextField,
  Divider,
  Switch,
  FormControlLabel,
  InputAdornment,
  Checkbox,
  Button,
} from "@mui/material";
import NumberInput from "../components/core/NumberInput";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import Chips from "../components/core/Chips";
import StyledDropzone from "../components/core/UplodInterface";
import { useDispatch } from "react-redux";
import {
  selectFecilities,
  selectFecilitiesincluded,
  selectFood,
  selectFoodincluded,
  selectValueAdded,
  setCount,
  setMaxPersons,
  setRegularPrice,
  setRegularPricePerPerson,
  setStayNamee,
  setWeekendPrice,
  setWeekendPricePerPerson,
  uploadMedia,
} from "../Redux/Action";

export default function StayDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [count, setCountt] = useState(0);
  const [max, setmax] = useState(0);
  const [perPersonToggle, setPerPersonToggle] = useState(false);
  const [regularPricee, setRegularPricee] = useState(0);
  const [weekendPricee, setWeekendPricee] = useState(0);
  const [priceSwitchOn, setPriceSwitchOn] = useState(false);
  const [selectedAmenities, setSelectedAmenities] = useState({});
  const [selectedAmenities2, setSelectedAmenities2] = useState({});
  const [array, setArray] = useState([]);
  const [array1, setArray1] = useState([]);
  const [foodOptions, setFoodOptions] = useState({
    welcomeDrink: false,
    lunch: false,
    snacks: false,
    barbeque: false,
    dinner: false,
    breakfast: false,
  });
  const [images, setImages] = useState<(File | null)[]>([]);

  const handleImageChange = (file) => {
    setImages([...images, file]);
  };

  const handleAddMore = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
        const target = e.target;
        if (target instanceof HTMLInputElement) {
            const file = target.files?.[0];
            if (file) {
                handleImageChange(file);
            }
        }
    };
    input.click();
};

  
  const handleFoodOptionChange = (option: string) => {
    setFoodOptions((prevOptions) => ({
      ...prevOptions,
      [option]: !prevOptions[option],
    }));
  }

  const handleRegularPriceChange = (event: { target: { value: any } }) => {
    const price = Number(event.target.value);
    setRegularPricee(price);
    if (perPersonToggle) {
      dispatch(setRegularPricePerPerson(price));
    } else {
      dispatch(setRegularPrice(price));
    }
  };

  const handleWeekendPriceChange = (event: { target: { value: any } }) => {
    const price = Number(event.target.value);
    setWeekendPricee(price);
    if (perPersonToggle) {
      console.log("perPersonToggle == true");
      dispatch(setWeekendPricePerPerson(price));
    } else {
      console.log("perPersonToggle == false");
      dispatch(setWeekendPrice(price));
    }
  };

  const onCountChange = (count: any) => {
    setCountt(count);
    dispatch(setCount(count));
  };

  const onMaxPersonsChange = (maxPersons: any) => {
    setmax(maxPersons);
    dispatch(setMaxPersons(maxPersons));
  };

  const handlePerPersonToggleChange = () => {
    setPerPersonToggle((prevToggle) => !prevToggle);
  };
  const [amenities, setAmenities] = useState([]);

  const handleAmenitiesChange = (amenityId) => {
    setSelectedAmenities((prevAmenities) => {
      const updatedAmenities = {
        ...prevAmenities,
        [amenityId]: !prevAmenities[amenityId],
      };

      const selectedAmenity = Object.entries(amenities).find(
        ([id, amenity]) => id === amenityId
      );

      if (
        selectedAmenity &&
        typeof selectedAmenity !== "undefined" &&
        "name" in selectedAmenity
      ) {
        const amenityWithName = selectedAmenity as { name: string };

        console.log("Selected amenity:", amenityWithName); // Log the selected amenity by name to the console
      }

      console.log("updatedAmenities below", updatedAmenities);
      return updatedAmenities;
    });
  };

  const handleAmenities2Change = (k: string | number) => {
    setSelectedAmenities2((prevAmenities) => {
      const updatedAmenities2 = {
        ...prevAmenities,
        [k]: !prevAmenities[k],
      };

      const selectedAmenity = Object.entries(amenities).find(
        ([id, k]) => id === k
      );

      if (
        selectedAmenity &&
        typeof selectedAmenity !== "undefined" &&
        "name" in selectedAmenity
      ) {
        const amenityWithName = selectedAmenity as { name: string };

        console.log("Selected amenity:", amenityWithName); // Log the selected amenity by name to the console
      }

      console.log("updatedAmenities below", updatedAmenities2);
      return updatedAmenities2;
    });
  };

  // Initialize selected amenities as all false

  // Set initial selected amenities

  const type = localStorage.getItem("type");

  const [stayName, setstayName] = useState("");

  useEffect(() => {
    (async () => {
      const master: any = await localStorage.getItem("master");
      setAmenities(await JSON.parse(master).amenities);
    })();
  }, []);

  console.log("amenities%%%", amenities);

  const onButtonClick = async () => {
    await localStorage.setItem("stayName", stayName);
    await localStorage.setItem("stay", JSON.stringify({ name: stayName }));
    navigate("/property-rules");
  };



  const onSave = () => {
    dispatch(setStayNamee(stayName));
    if (priceSwitchOn) {
      console.log("omnnnnn price", foodOptions);
      dispatch(selectFoodincluded(foodOptions));
    } else {
      console.log("offff price", foodOptions);
      dispatch(selectFood(foodOptions));
    }

    interface Amenity {
      name: string;
      _id: string;
      amenity: string;
      // Add other properties as needed
    }


    Object.keys(selectedAmenities).forEach((key) => {
      console.log("selectedAmenities^^^", selectedAmenities[key]);
      console.log("keyyy", key);
      const filteredArray = amenities
        .filter((element, index) => selectedAmenities[index] == key)
        .map((item) => {
          const { name } = item;
          console.log("name!!!", name);
          return name;
        });
      console.log("filteredArray---", filteredArray);
      setArray(filteredArray);
    });

    {
      priceSwitchOn
        ? dispatch(selectFecilitiesincluded(array))
        : dispatch(selectFecilities(array));
    }

    Object.keys(selectedAmenities2).forEach((key) => {
      console.log("selectedAmenities<<<<", selectedAmenities2[key]);
      console.log("keyyy", key);
      const filteredArray = amenities
        .filter((element, index) => selectedAmenities2[index] == key)
        .map((item) => {
          const { name } = item;
          console.log("name!!!", name);
          return name;
        });
      console.log("filteredArray---> values", filteredArray);
      setArray1(filteredArray);
    });

    dispatch(selectValueAdded(array1))
    console.log('attttt666',images )
   dispatch(uploadMedia(images))
    navigate("/property-rules");
  };

  return (
    <>
      <RegAppHeader
        openMenuOverlay={function (): void {
          console.log("function not implemented");
        }}
      ></RegAppHeader>
      <Stack gap={4} p={4}>
        <Box>
          <Typography variant="h5">Add {type} details</Typography>
          <Typography variant="caption">
            You can always give them unique names later.
          </Typography>
        </Box>
        <TextField
          label={type + " name"}
          placeholder="Only show in Edit "
          variant="outlined"
          onChange={(stay) => setstayName(stay.target.value)}
          value={stayName}
          sx={{
            borderRadius: "10px",
          }}
        ></TextField>
        <Stack
          direction={"row"}
          gap={4}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body1">Number of {type} available</Typography>
          <NumberInput onChange={onCountChange} count={count}></NumberInput>
        </Stack>
        <Stack
          direction={"row"}
          gap={4}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body1">Max person in each {type} </Typography>
          <NumberInput count={max} onChange={onMaxPersonsChange}></NumberInput>
        </Stack>
        <Divider />
        <Stack
          direction={"row"}
          gap={4}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Typography variant="h6">Price</Typography>
          <FormControlLabel
            control={
              <Switch
                checked={perPersonToggle}
                onChange={handlePerPersonToggleChange}
                size="small"
              />
            }
            label="per person"
          />
        </Stack>
        <Stack
          direction={"row"}
          gap={2}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <TextField
            value={regularPricee}
            onChange={handleRegularPriceChange}
            label="regular price "
            placeholder="00.00"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CurrencyRupeeIcon
                    sx={{
                      opacity: "0.4",
                    }}
                  ></CurrencyRupeeIcon>
                </InputAdornment>
              ),
              style: {
                borderRadius: "10px",
              },
            }}
            variant="outlined"
          ></TextField>

          <TextField
            value={weekendPricee}
            onChange={handleWeekendPriceChange}
            label="weekend price "
            placeholder="00.00"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CurrencyRupeeIcon
                    sx={{
                      opacity: "0.4",
                    }}
                  ></CurrencyRupeeIcon>
                </InputAdornment>
              ),
              style: {
                borderRadius: "10px",
              },
            }}
            variant="outlined"
          ></TextField>
        </Stack>
        <Stack
          direction={"row"}
          gap={4}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Typography variant="subtitle1">Included in price</Typography>
          <FormControlLabel
            control={
              <Switch
                checked={priceSwitchOn}
                onChange={() => setPriceSwitchOn(!priceSwitchOn)}
                size="small"
              />
            }
            label=""
          />
        </Stack>

        <Stack direction={"row"}>
          <Typography
            variant="h6"
            sx={{
              textOrientation: "sideways",
              writingMode: "vertical-lr",
              padding: "10px 10px 10px 0",
              margin: "0",
              alignContent: "center",
            }}
          >
            FOOD
          </Typography>
          <Stack direction={"row"} gap={0} useFlexGap flexWrap="wrap">
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={foodOptions.welcomeDrink}
                  onChange={() => handleFoodOptionChange("welcomeDrink")}
                />
              }
              label="Welcome Drink"
            />
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={foodOptions.lunch}
                  onChange={() => handleFoodOptionChange("lunch")}
                />
              }
              label="Lunch"
            />
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={foodOptions.snacks}
                  onChange={() => handleFoodOptionChange("snacks")}
                />
              }
              label="Snacks"
            />
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={foodOptions.barbeque}
                  onChange={() => handleFoodOptionChange("barbeque")}
                />
              }
              label="Barbeque"
            />
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={foodOptions.dinner}
                  onChange={() => handleFoodOptionChange("dinner")}
                />
              }
              label="Dinner"
            />
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={foodOptions.breakfast}
                  onChange={() => handleFoodOptionChange("breakfast")}
                />
              }
              label="Breakfast"
            />
          </Stack>
        </Stack>
        <Stack direction={"row"}>
          <Typography
            variant="h6"
            sx={{
              textOrientation: "sideways",
              writingMode: "vertical-lr",
              padding: "10px 10px 10px 0",
              margin: "0",
              alignContent: "center",
            }}
          >
            FACILITIES
          </Typography>
          <Stack direction={"row"} gap={1} useFlexGap flexWrap="wrap">
            {Object.entries(amenities).map(([amenityId, amenity]: any) => {
              return (
                <Chips
                  key={amenityId}
                  text={amenity.name}
                  selected={selectedAmenities[amenityId]}
                  handleSelected={() => handleAmenitiesChange(amenityId)}
                />
              );
            })}
          </Stack>
        </Stack>
        <Divider />
        <Stack>
          <Typography variant="h6">Value added facilities</Typography>
          <Typography variant="caption" className="mb-5" gutterBottom>
            guest pay extra charges for these
          </Typography>
          <Stack
            direction={"row"}
            gap={1}
            useFlexGap
            flexWrap="wrap"
            className="mt-5"
          >
            {Object.entries(amenities).map(([k, v]: any) => (
              <Chips
                key={k}
                text={v.name}
                selected={selectedAmenities2[k]}
                handleSelected={() =>handleAmenities2Change(k )}
              />
            ))}
          </Stack>
        </Stack>
        <Stack>
          <Typography variant="h6" gutterBottom>
            Media
          </Typography>
          <Stack direction={"row"} gap={1} useFlexGap flexWrap="wrap">
          {/* {images.map((img, index) => (
   <img
   key={index}
   src={URL.createObjectURL(img)}
   alt={`Uploaded ${index + 1}`}
   style={{ width: "300px", height: "200px", objectFit: "cover" }}
 />
    ))} */}

{images.map((img, index) => (
  img !== null ? (
    <img
      key={index}
      src={URL.createObjectURL(img)}
      alt={`Uploaded ${index + 1}`}
      style={{ width: "300px", height: "200px", objectFit: "cover" }}
    />
  ) : null
))}
          </Stack>
        </Stack>
        <Stack>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Button
              variant="outlined"
              size="large"
              sx={{
                borderRadius: "10px",
              }}
              onClick={handleAddMore}
              fullWidth
            >
              ADD more
            </Button>
            <Button
              variant="contained"
              size="large"
              color="success"
              sx={{
                color: "white",
                borderRadius: "10px",
              }}
              onClick={onSave}
              fullWidth
            >
              save
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}

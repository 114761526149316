import { FunctionComponent, useCallback } from "react";
import RegAppHeader from "../components/core/RegAppHeader";
import Lottie from "react-lottie-player";
import lottieJson from "../lottieFiles/animation_publish.json";
import {
  Button,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Stack,
  Typography,
} from "@mui/material";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";

export default function Publish() {
  return (
    <>
      <RegAppHeader openMenuOverlay={function (): void {
       console.log('function not implemented')
      } } />
      <Lottie
        loop
        animationData={lottieJson}
        play
        style={{ width: "100vw", height: "40vh", backgroundColor: "#000" }}
      />

      <Stack gap={2} className="text-center p-5">
        <Typography variant="h6">
          <strong>Awsome!</strong> we are checking up few details and will get
          back to you soon.
        </Typography>
        <Typography variant="caption">meanwhile</Typography>
        <Button variant="outlined">go to dashboard</Button>
        <Typography variant="caption">or</Typography>
        <MenuList>
          <MenuItem>
            <ListItemIcon className="opacity-50">
              <HelpOutlineRoundedIcon />
            </ListItemIcon>
            <ListItemText className="text-left">How to add booking?</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemIcon className="opacity-50">
              <HelpOutlineRoundedIcon />
            </ListItemIcon>
            <ListItemText className="text-left">What is co-host?</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemIcon className="opacity-50">
              <HelpOutlineRoundedIcon />
            </ListItemIcon>
            <ListItemText className="text-left">How to add co-host?</ListItemText>
          </MenuItem>
        </MenuList>
      </Stack>
    </>
  );
}

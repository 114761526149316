import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import "./ribbon.css";
import { Divider } from "@mui/material";
import DateRangeRoundedIcon from "@mui/icons-material/DateRangeRounded";

export default function EventCard(props) {
  return (
    <Card
      sx={{
        minWidth: 280,
        padding: 1,
        borderRadius: 2,
        position: "relative",
        mx: "8px",
      }}
    >
      <CardMedia
        component="img"
        alt={props.name}
        height="160"
        image={props.img}
        sx={{
          borderRadius: 2,
          boxShadow: 4,
        }}
      />
      <div className="priceDetails">
        <p className="ribbon">
          <span className="text">
            <strong className="bold">
              <CurrencyRupeeIcon
                sx={{
                  fontSize: "18px",
                }}
              />
            </strong>
            {props.price}/-
          </span>
        </p>
        <small className="badge bg-dark align-self-end me-3 duration">
          {props.duration}
        </small>
      </div>
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          {props.name}
        </Typography>
        <Divider
          sx={{
            mt: 2,
          }}
        ></Divider>
      </CardContent>
      <CardActions className="m-0 flex justify-between">
        <Typography
          variant="caption"
          className="flex items-center font-bold"
          sx={{
            color: "#1C75BC",
          }}
        >
          <DateRangeRoundedIcon className="me-2" />
          <strong>{props.startdate}</strong> {" - "} 
          <strong>{props.enddate}</strong>
        </Typography>
        <Button size="small" variant="outlined">
          join
        </Button>
      </CardActions>
    </Card>
  );
}

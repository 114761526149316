import { FunctionComponent, useEffect } from "react";
import TypemonogramIcon from "../components/TypemonogramIcon";
import { useNavigate } from "react-router-dom";
import { MASTER } from "../services/api";
import { axiosInstance } from "../services/interceptor";
import axios from "axios";

const Splash: FunctionComponent = () => {
  const navigate = useNavigate();
  const isReturning = localStorage.getItem('isReturning') ? true : false;
  useEffect(()=>{
  (async () => {
    const master = await axiosInstance.get(MASTER);
    if(master.data)
      localStorage.setItem('master',JSON.stringify(master.data.data));
    console.log(master);
    // const master = await axios.get(MASTER, {headers:{
    //   'content-type': 'application/json',
    //   'clientid':'abbas_system',
    //   'partnertoken':'$2b$10$aoAA/HxIXK2DvCcT81diQuQBAj/mlOUpnQPHyQevo42DqffB8u0zC',
    //   'domaintoken':'4044da19936f8990c9954f4270b164f4638dfd6b',
    //   'origin':'test.thecloudvision.com',
    // }});
    // console.log(master);
  })();
  // master();
//     (async()=>{
// await 
//   },());
    setTimeout(()=>{

      if(isReturning) navigate('/login');
      if(!isReturning) navigate('/boarding');
    },3000)
  },[])
  return (
    <div className="relative w-full h-[100vh] bg-[url(/public/splash@3x.png)] bg-cover bg-no-repeat bg-[top] text-left text-[50px] text-white font-proza-libre">
      <b className="absolute top-[35%] left-[30%]">HOST</b>
      <TypemonogramIcon
        typemonogramIconTypemonog="/logo.svg"
        typemonogramIconPosition="relative"
        typemonogramIconWidth="20%"
        typemonogramIconHeight="12.5%"
        typemonogramIconTop="20%"
        typemonogramIconRight="40%"
        typemonogramIconBottom="70%"
        typemonogramIconLeft="40%"
        typemonogramIconMaxWidth="100%"
        typemonogramIconOverflow="hidden"
        typemonogramIconMaxHeight="100%"
      />
    </div>
  );
};

export default Splash;

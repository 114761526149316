import { FunctionComponent, useCallback } from "react";
import TypemonogramIcon from "../components/TypemonogramIcon";
import { useNavigate } from "react-router-dom";

const Boarding: FunctionComponent = () => {
  const navigate = useNavigate();

  const onSkipClick = useCallback(() => {
    localStorage.setItem("isReturning","1");
    navigate("/login");
  }, [navigate]);

  return (
    <div className=" [background:linear-gradient(rgba(173,_180,_189,_0.25),_rgba(173,_180,_189,_0.25)),_#fff] w-full h-[100vh] overflow-x-auto text-left text-lg text-steelblue-100 font-proza-libre flex flex-col">
      <div className="w-full p-5 box-border items-center justify-between flex">
        <TypemonogramIcon
          typemonogramIconTypemonog="/logo1.svg"
          typemonogramIconPosition="relative"
          typemonogramIconWidth="100px"
          typemonogramIconHeight="40px"
          typemonogramIconTop="unset"
          typemonogramIconRight="unset"
          typemonogramIconBottom="unset"
          typemonogramIconLeft="unset"
          typemonogramIconMaxWidth="unset"
          typemonogramIconOverflow="unset"
          typemonogramIconMaxHeight="unset"
        />
        <img
          className="relative w-6 h-[25.08px] overflow-hidden shrink-0 cursor-pointer"
          alt=""
          src="/icons8.svg"
          onClick={onSkipClick}
        />
      </div>

      <div className="px-5 pb-5 flex gap-[10px] overflow-x-auto">
        <div className="rounded-3xs bg-white shadow-[0px_2px_10px_rgba(0,_0,_0,_0.15)]">
          <img
            className="object-cover h-[50vh] rounded-t-3xs w-[320px]"
            alt=""
            src="/rectangle-108@2x.png"
          />
          <div className="leading-[1.9rem] items-center p-5 font-open-sans">furutcamps makes it simple and secure to host people. You are in full control of your availability, prices,  rules, and how you interact with guests.</div>
        </div>

        <div className="rounded-3xs bg-white shadow-[0px_2px_10px_rgba(0,_0,_0,_0.15)] font-open-sans">
          <img
            className="object-cover h-[24vh] mt-10 mx-10"
            alt=""
            src="/noun-gluten-free-1002144.svg"
          />
          <div className="leading-[1.9rem]  items-center p-5">
            <p className="text-2xl font-proza-libre font-bold">
              {`Start Free!`} <br />
              {`pay as you go`}
            </p>
            {`List your campsite, stays, experiences, or special events without any sign-up charges. Add pictures, videos, amenities, special services, activities, and much more. `}
          </div>
        </div>

        <div className="rounded-3xs bg-white shadow-[0px_2px_10px_rgba(0,_0,_0,_0.15)] font-open-sans">
          <img
            className="object-cover mt-10 mx-10 h-[24vh]"
            alt=""
            src="/noun-calendar-2481234.svg"
          />
          <div className="leading-[1.9rem]  items-center p-5">
            <p className="text-2xl font-proza-libre font-bold">
              {`HOST!`} <br />
              {`when and how you want`}
            </p>
            {`Choose your schedule, prices, camp rules, reservation policy, cancellation policy, and other requirements for the guests. We’re here to help and assist you along the way. `}
          </div>
        </div>

        <div className="rounded-3xs bg-white shadow-[0px_2px_10px_rgba(0,_0,_0,_0.15)] font-open-sans">
          <img
            className="object-cover mt-10 mx-10 h-[24vh]"
            alt=""
            src="/noun-paid-training-3339906.svg"
          />
          <div className="leading-[1.9rem]  items-center p-5">
            <p className="text-2xl font-proza-libre font-bold">
              {`Welcome guest!`} <br />
              {`get paid`}
            </p>
            {`Once your listing is live, qualified guests can book the campsite
              as per the availability. You can message them with questions
              before you confirm their booking.`}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Boarding;

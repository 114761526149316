import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Stack,
  TextField,
  Button,
  InputAdornment,
} from "@mui/material";
import { Person, Email, Smartphone } from "@mui/icons-material";

const SignUpForm: FunctionComponent = () => {
  const navigate = useNavigate();
  const [mobile,setMobile]=useState<any>();
  useEffect(()=>{
    (async()=>{
    setMobile(await localStorage.getItem('mobile'))
  })()
  },[])
  const onButtonClick = useCallback(() => {
    navigate("/verify-otp");
  }, [navigate]);

  return (
    <>
      <Stack spacing={2} className="w-[100%]">
        <TextField
          label="full name"
          placeholder="Firstname Lastname"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Person
                  sx={{
                    opacity: "0.4",
                  }}
                ></Person>
              </InputAdornment>
            ),
            style: {
              borderRadius: "10px",
            },
          }}
          variant="outlined"
          fullWidth
        ></TextField>
        <TextField
          label="email"
          placeholder="example@example.com"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Email
                  sx={{
                    opacity: "0.4",
                  }}
                ></Email>
              </InputAdornment>
            ),
            style: {
              borderRadius: "10px",
            },
          }}
          variant="outlined"
        ></TextField>

        <TextField
          label="phone number"
          placeholder="Mobile number"
          onChange={(mob:any) => setMobile(mob)}
          value={mobile}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Smartphone
                  sx={{
                    opacity: "0.4",
                  }}
                ></Smartphone>
              </InputAdornment>
            ),
            style: {
              borderRadius: "10px",
            },
          }}
          variant="outlined"
        ></TextField>
        <Button
          variant="contained"
          size="large"
          color="success"
          sx={{
            color: "white",
            borderRadius: "10px",
          }}
          onClick={onButtonClick}
        >
          SIGN UP
        </Button>
      </Stack>
    </>
  );
};

export default SignUpForm;

import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Box } from "@mui/material";
import { styled} from "@mui/material/styles";
import { useSelector } from "react-redux";

const StyledImg = styled(ImageListItem)<{ component?: React.ElementType }>({
  "& .MuiImageListItem-img": {
    maxHeight: "30vh",
    maxWidth: "90vw"
  },
});



export default function PropImageList() {
  const imagess = useSelector((state: any) => state);
  console.log('mmmmm',imagess);

//   const selectedMediaa = useSelector((state:any) => state);
// console.log('selectedMedia',selectedMediaa)
     

  return (
    <Box className="overflow-x-auto overflow-y-hidden p-0 m-0 h-[30vh]">
      <ImageList cols={10} gap={1} className="m-0">
        {itemData.map((item) => (
          <StyledImg key={item.img} className="p-0 m-0 w-[90vw]">
            <img
              src={`${item.img} w100`}
              srcSet={`${item.img}`}
              alt={item.title}
              loading="lazy"
            />
          </StyledImg>
        ))}
      </ImageList>
    </Box>
  );
}

const itemData = [
  {
    img: "https://source.unsplash.com/random/?camping?order_by=curated&orientation=landscape&license=free&1",
    title: "Property key Image 01",
  },
  {
    img: "https://source.unsplash.com/random/?camping?order_by=curated&orientation=landscape&license=free&2",
    title: "Property key Image 01",
  },
  {
    img: "https://source.unsplash.com/random/?camping?order_by=curated&orientation=landscape&license=free&3",
    title: "Property key Image 01",
  },
  {
    img: "https://source.unsplash.com/random/?camping?order_by=curated&orientation=landscape&license=free&4",
    title: "Property key Image 01",
  },
  {
    img: "https://source.unsplash.com/random/?camping?order_by=curated&orientation=landscape&license=free&5",
    title: "Property key Image 01",
  },
  {
    img: "https://source.unsplash.com/random/?camping?order_by=curated&orientation=landscape&license=free&6",
    title: "Property key Image 01",
  },
  {
    img: "https://source.unsplash.com/random/?camping?order_by=curated&orientation=landscape&license=free&7",
    title: "Property key Image 01",
  },
  {
    img: "https://source.unsplash.com/random/?camping?order_by=curated&orientation=landscape&license=free&8",
    title: "Property key Image 01",
  },
  {
    img: "https://source.unsplash.com/random/?camping?order_by=curated&orientation=landscape&license=free&9",
    title: "Property key Image 01",
  },
  {
    img: "https://source.unsplash.com/random/?camping?order_by=curated&orientation=landscape&license=free&10",
    title: "Property key Image 01",
  },
];
